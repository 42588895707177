import ModalUpload from 'containers/Modal/modal-documents-upload'
import GeneralComment from 'containers/general-comment'
import LateralBar from 'containers/lateral-bar'
import { AlertContext } from 'providers/alert'
import { CustomerContext } from 'providers/customer'
import { LATERAL_MENU_OPTIONS, LateralBarContext } from 'providers/lateral-bar'
import { ApiBuyerRepository } from 'services/buyer-suppcards/buyer.repository'
import { getDocumentList, uploadDocumentsInChunks } from 'services/documents'
import { BuildSection } from 'templates/actions/document-actions/build-section'
import { getUserRoles } from 'utils/functions/role-manager'
import { useAuth } from 'utils/hooks/auth'
import Router from 'utils/router'
import {
  CREDIT_ANALYSIS,
  FILE_STAGES,
  FILE_STATES,
} from 'utils/schemas/documents'
import { ONBOARDING_STATES } from 'utils/schemas/workflows/onboarding'

import { LoadingButton } from '@mui/lab'
import { Button, ButtonGroup } from '@mui/material'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'
import { ApiError, FormValidationError } from 'utils/schemas/errors'
import { Icon } from '@keoworld/gbl-ui-kit'

const BackgroundReviewReassessment = () => {
  const [files, setFiles] = useState({})
  const [isPublic, setIsPublic] = useState(true)
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const [isValidating, setIsValidating] = useState(false)
  const [comment, setComment] = useState('')
  const [commentBarInfo, setCommentBarInfo] = useState(false)
  const [modalConfig, setModalConfig] = useState()

  const { setAction, setSelectedOption } = useContext(LateralBarContext)
  const { setAlert } = useContext(AlertContext)
  const { customer } = useContext(CustomerContext)
  const { user } = useAuth()

  const { customerId } = useParams()
  const navigate = useNavigate()

  const [userRole] = getUserRoles()
  const [reassessmentReject, reassessmentApprove] =
    ONBOARDING_STATES.BACKGROUND_REVIEW_REASSESSMENT.actions

  const validateFiles = () => {
    setIsValidating(true)

    const fileIsMissing = CREDIT_ANALYSIS.some(({ typeId }) => {
      const filesByTypeId = files[typeId] || []

      const filesToValidate = filesByTypeId.filter(
        (file) => file.status !== FILE_STATES.DELETED
      )

      return filesToValidate.length === 0
    })

    if (fileIsMissing)
      throw new FormValidationError('Por favor sube los documentos requeridos')

    if (!Boolean(comment && comment !== ''))
      throw new FormValidationError('Por favor ingresa un comentario')
  }

  const saveDocuments = async (status) => {
    const response = await uploadDocumentsInChunks({
      files,
      customerId: customer.id,
      user,
    })

    return response.map(({ id }) => ({ id, status }))
  }

  const executeTransition = async ({ data, action }) => {
    let event

    const { fileList, comment, currentState, commentIsPublic } = data

    const transitionData = {
      'fileList:multiUpdate': fileList,
      'comment:comment': comment,
      'wfState:comment': currentState,
      'isPublic:comment': commentIsPublic,
      'uuid:comment': user.uid,
      'role:comment': userRole,
      customerId: customer.id,
      'customerId:status': customer.id,
      'id:assignation': customer.assignationId,
      'uuid:assignation': user.uid,
      'role:assignation': userRole,
      'customerId:assignation': customer.id,
    }

    switch (action) {
      case 'REJECT_BUYER':
        event = reassessmentReject.event
        transitionData['wfState:status'] = reassessmentReject.nextState
        transitionData['status:status'] = reassessmentReject.status
        break
      case 'APPROVE_BUYER':
        event = reassessmentApprove.event
        transitionData['wfState:status'] = reassessmentApprove.nextState
        break
      default:
    }

    await ApiBuyerRepository.doReassessmentEvent(
      customer.externalId,
      event,
      transitionData
    )
  }

  const approveBackgroundReview = async () => {
    validateFiles()

    const fileList = await saveDocuments(FILE_STATES.ACCEPTED)

    await executeTransition({
      action: 'APPROVE_BUYER',
      data: {
        fileList,
        comment,
        currentState: customer.wfState,
        commentIsPublic: isPublic,
      },
    })

    navigate(Router.Root)
    setAction(undefined)
    setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
  }

  const errorWrapper = async (fn) => {
    try {
      await fn()
    } catch (error) {
      if (error instanceof FormValidationError) {
        return setAlert({
          label: error.message,
          title: 'Error',
          type: 'error',
        })
      }

      if (error instanceof ApiError) {
        return setAlert({
          type: error.statusCode === 400 ? 'warning' : 'error',
          title: error.title,
          label: error.message,
        })
      }

      setAlert({
        type: 'error',
        title: 'Ups! Algo salio mal',
        label: 'Ha ocurrido un error al enviar los documentos',
      })
    }
  }

  const handleSave = async () => {
    try {
      setIsLoadingSubmit(true)
      await saveDocuments(FILE_STATES.DRAFT)
      navigate(-1)
      setAction(undefined)
    } catch (error) {
      console.error(error.message)
      const alert = {
        label:
          'Ha ocurrido un error al guardar los archivos, por favor intenta de nuevo',
        title: 'Error',
        type: 'error',
      }
      if (error.message === 'error saving files') {
        navigate(Router.Root)
        setAction(undefined)
        setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
      }
      setAlert(alert)
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  /* Fetch saved files Effect */
  useEffect(() => {
    try {
      const fetchFiles = async () => {
        const { data: savedFilesData } = await getDocumentList(customerId, {
          stage: FILE_STAGES.RISK,
          status: [
            FILE_STATES.DRAFT,
            FILE_STATES.ACCEPTED,
            FILE_STATES.REFUSED,
          ].join(','),
        })
        let savedFiles = {}
        CREDIT_ANALYSIS.forEach((schema) => {
          let documents = savedFilesData
            .filter((itm) => itm.typeId === schema.typeId)
            .map((itm) => {
              const extension = itm.fileExtension ? `.${itm.fileExtension}` : ''
              return {
                name: `${itm.fileName}${extension}`,
                size: itm.fileSize,
                status: FILE_STATES.DRAFT,
                id: itm.fileId,
                typeId: itm.typeId,
              }
            })

          savedFiles[schema.typeId] = documents
        })
        setFiles(savedFiles)
      }
      if (customerId) fetchFiles()
    } catch (err) {
      console.error('err', err)
    }
  }, [customerId])

  return (
    <CreditAnalysisStyled
      commentWarning={isValidating && (!comment || comment === '')}
    >
      <h3>Análisis de riesgo</h3>
      <CustomerDocumentsStyled>
        <BuildSection
          allowUpload
          allowDelete
          allowUpdate
          schema={CREDIT_ANALYSIS}
          title={'Documentos'}
          files={files}
          setFiles={setFiles}
          isValidating={isValidating}
          setCommentBarInfo={setCommentBarInfo}
        />
      </CustomerDocumentsStyled>
      <GeneralComment
        className='comment-section'
        comment={comment}
        setComment={setComment}
        isPublic={isPublic}
        setIsPublic={setIsPublic}
        error={isValidating && comment === ''}
      />
      <section className='action-group'>
        <ButtonGroup>
          <Button className='go-back' onClick={() => navigate(-1)}>
            Cancelar
          </Button>
          <LoadingButton
            className='save-documents'
            isLoading={isLoadingSubmit}
            onClick={() =>
              setModalConfig({
                message: `Esta acción guardará los documentos, pero
                  no va a cambiar el estado del prospecto.
                  ¿Estás seguro de querer continuar?`,
                actionLabel: 'Aceptar',
                action: handleSave,
              })
            }
          >
            Guardar
          </LoadingButton>
        </ButtonGroup>
        <ButtonGroup>
          <LoadingButton
            className='reassessment-approbation'
            isLoading={isLoadingSubmit}
            endIcon={<Icon name='arrow_forward' />}
            onClick={() =>
              setModalConfig({
                message: `Esta acción aprobará al prospecto,
                  ¿Estás seguro de querer continuar?`,
                actionLabel: 'Aceptar',
                action: async () => {
                  setIsLoadingSubmit(true)
                  await errorWrapper(approveBackgroundReview)
                  setIsLoadingSubmit(false)
                },
              })
            }
          >
            Aprobar
          </LoadingButton>
        </ButtonGroup>
      </section>

      {modalConfig && (
        <ModalUpload
          setOpenModalUpload={() => setModalConfig(undefined)}
          isLoadingSubmit={isLoadingSubmit}
          messages={{
            msg: modalConfig.message,
            msgButton: modalConfig.actionLabel,
          }}
          handleSave={modalConfig.action}
        />
      )}

      {commentBarInfo && (
        <LateralBar
          setLateralBar={setCommentBarInfo}
          documentName={commentBarInfo?.fileLabel}
          fileId={commentBarInfo?.fileId}
        />
      )}
    </CreditAnalysisStyled>
  )
}

const CreditAnalysisStyled = styled.div`
  padding: 1rem 2rem;
  .loading span {
    background-color: white;
  }
  .content {
    display: flex;
  }
  .switch-content {
    .comment {
      line-height: 0px;
      margin: 1rem;
    }
  }
  .switch-div {
    display: flex;
    margin: 0rem 1rem;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin: 0.5rem 0rem 0.5rem 0.5rem;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 8px;
    left: 0px;
    right: 14px;
    bottom: 11px;
    background-color: #939191;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 22px;
    width: 22px;
    left: -1px;
    bottom: -3.5px;
    background-color: #bab9b9;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #939191;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
  F .documents-risk {
    width: 80%;
  }
  .blBNhs {
    min-width: 60%;
  }
  .textarea {
    padding: 10px;
    font-family: 'Poppins';
    font-weight: bold;
    border-radius: 21px;
    width: 55%;
    height: 108px;
    margin: 1rem 0rem;
    resize: none;
    border-color: ${({ theme, commentWarning }) =>
      commentWarning ? theme.alertTheme.error.backgroundColor : 'none'};
  }
  h3 {
    margin: 1rem auto;
  }

  .comment-section {
    margin-block: 1rem;
  }

  section.action-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;

    min-width: 300px;

    button {
      &.go-back {
        border-color: #ff0045;
        color: #ff0045;
      }
      &.save-documents {
        border-color: #00172d;
        color: #00172d;
      }
      &.reassessment-rejection {
        background-color: #ff0045;
        border-color: #ff0045;
        color: #fff;
      }
      &.reassessment-approbation {
        background-color: #00172d;
        border-color: #00172d;
        color: #fff;
      }
    }
  }
`
const CustomerDocumentsStyled = styled.section`
  .center-loading {
    height: 50vh;
    display: flex;
    align-items: center;
    div {
      width: 150px;
    }
  }
  .checked {
    ${({ theme }) => theme.buttons.outline.hover}
  }
  h3 {
    margin: 1rem auto;
  }

  section {
    width: 65%;
  }
`

export default BackgroundReviewReassessment
