import PropTypes from 'prop-types'
import styled from 'styled-components'
import * as CurrencyFormatter from 'utils/functions/handlers-currency'
import { currency } from 'utils/functions/formatters'
import { DEFAULT_CURRENCY } from 'utils/constants'

const DEBT_CAPACITY_STATUS_LABEL = {
  APPROVED: 'Aprobado',
  REJECTED: 'Rechazado',
  PENDING: 'Pendiente',
}

const ContainerStyled = styled.div`
  .capacity-row {
    display: grid;
    grid-template-columns: 2fr 2fr 1fr;
    margin-block: 1rem;
  }

  capacity-data {
    display: flex;
    flex-direction: column;

    text-transform: uppercase;
  }

  header {
    label {
      font-weight: normal;
      font-size: 12pt;
    }

    value {
      font-weight: bold;
      font-size: 18pt;
    }
  }

  footer {
    label {
      font-weight: normal;
      font-size: 10pt;
    }

    value {
      font-weight: bold;
      font-size: 12pt;
    }
  }
`

const DebtCapacityInformation = ({ debtCapacity }) => {
  const {
    currency: currencyISO = DEFAULT_CURRENCY,
    amount = 0,
    maxTerm = 0,
    status,
    usedAmount = 0,
    availableAmount = 0,
  } = debtCapacity

  return (
    <ContainerStyled>
      <header className='capacity-row'>
        <capacity-data>
          <label>Divisa</label>
          <value>{currencyISO}</value>
        </capacity-data>
        <capacity-data>
          <label>Monto de la capacidad</label>
          <value>{currency(CurrencyFormatter.extractNumber(amount))}</value>
        </capacity-data>
        <capacity-data>
          <label>Plazo máximo</label>
          <value>{maxTerm}</value>
        </capacity-data>
      </header>
      <footer className='capacity-row'>
        {status !== 'APPROVED' && (
          <>
            <capacity-data>
              <label>Estado</label>
              <value>{DEBT_CAPACITY_STATUS_LABEL[status]}</value>
            </capacity-data>
          </>
        )}
        {status === 'APPROVED' && (
          <>
            <capacity-data>
              <label>Monto utilizado en lineas</label>
              <value>
                {currency(CurrencyFormatter.extractNumber(usedAmount))}
              </value>
            </capacity-data>
            <capacity-data>
              <label>Monto disponible</label>
              <value>
                {currency(CurrencyFormatter.extractNumber(availableAmount))}
              </value>
            </capacity-data>

            <capacity-data>
              <label>Estado</label>
              <value>{DEBT_CAPACITY_STATUS_LABEL[status]}</value>
            </capacity-data>
          </>
        )}
      </footer>
    </ContainerStyled>
  )
}

DebtCapacityInformation.propTypes = {
  debtCapacity: PropTypes.shape({
    amount: PropTypes.number,
    currency: PropTypes.string,
    status: PropTypes.string,
    usedAmount: PropTypes.number,
    availableAmount: PropTypes.number,
  }).isRequired,
}

export default DebtCapacityInformation
