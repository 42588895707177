import { INPUT_TYPES, VALIDATOR_TYPES } from 'containers/form'
import { AVAILABLE_CURRENCIES } from 'utils/constants'

export const DEBT_CAPACITY_SCHEMA = [
  {
    label: 'Moneda',
    name: 'currency',
    inputType: INPUT_TYPES.SELECT,
    options: AVAILABLE_CURRENCIES.map((currencyCode) => ({
      value: currencyCode,
      label: currencyCode,
    })),
  },
  {
    inputType: INPUT_TYPES.CURRENCY,
    label: 'Monto',
    placeholder: 'Campo obligatorio*',
    name: 'amount',
    validatorType: VALIDATOR_TYPES.CURRENCY,
    errorMessage: 'Ingresa un monto valido',
    validatorParams: { min: 1, max: 9999999999.99 },
  },
  {
    inputType: INPUT_TYPES.TEXT_FIELD,
    label: 'Plazo máximo',
    placeholder: 'Campo obligatorio*',
    name: 'maxTerm',
    validatorType: VALIDATOR_TYPES.INTEGER_NUMBER,
    errorMessage: 'Ingresa un plazo válido',
    validatorParams: { min: 1, max: 9999 },
  },
]
