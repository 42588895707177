import LateralBar from 'containers/lateral-bar'
import ConfirmationModal from 'modules/shared/components/confirmation-modal'
import { AlertContext } from 'providers/alert'
import { CustomerContext } from 'providers/customer'
import { LATERAL_MENU_OPTIONS, LateralBarContext } from 'providers/lateral-bar'
import { ApiBuyerRepository } from 'services/buyer-suppcards/buyer.repository'
import { getDocumentList, uploadDocumentsInChunks } from 'services/documents'
import { BuildSection } from 'templates/actions/document-actions/build-section'
import { useAuth } from 'utils/hooks/auth'
import Router from 'utils/router'
import {
  CREDIT_ANALYSIS,
  FILE_STAGES,
  FILE_STATES,
} from 'utils/schemas/documents'
import { ApiError, FormValidationError } from 'utils/schemas/errors'
import { ONBOARDING_STATES } from 'utils/schemas/workflows/onboarding'

import { Button, LoadingAnimation } from '@keoworld/gbl-ui-kit'
import { useContext, useEffect, useMemo, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import styled from 'styled-components'

const CreditAnalysis = () => {
  const [files, setFiles] = useState({})
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const [isValidating, setIsValidating] = useState(false)
  const [commentBarInfo, setCommentBarInfo] = useState(false)
  const [modalConfiguration, setModalConfiguration] = useState()

  const { setAction, setSelectedOption } = useContext(LateralBarContext)
  const { setAlert } = useContext(AlertContext)
  const { customer } = useContext(CustomerContext)
  const { user } = useAuth()

  const { customerId } = useParams()
  const navigate = useNavigate()

  const [userRole] = useMemo(() => {
    if (!user) return []
    return user.iam.roles
  }, [user])

  const validateDocuments = () => {
    const BURO_FILE_TYPE = 23

    setIsValidating(true)

    const missingFiles = CREDIT_ANALYSIS.some(({ typeId }) => {
      let documents = files[typeId] ?? []
      documents = documents.filter(
        (document) => document.status !== FILE_STATES.DELETED
      )
      return documents.length === 0
    })

    if (missingFiles) {
      throw new FormValidationError(
        `Por favor carga todos los documentos
         antes de realizar esta acción`
      )
    }

    const fileTypeRejected = CREDIT_ANALYSIS.find(({ typeId }) => {
      let documents = files[typeId] ?? []
      return documents
        .filter((document) => document.status !== FILE_STATES.DELETED)
        .some((document) => document.status !== FILE_STATES.ACCEPTED)
    })

    if (!fileTypeRejected) return 'approve'
    if (fileTypeRejected.typeId === BURO_FILE_TYPE) return 'rejectBuro'
    return 'reject'
  }

  const saveDocuments = async () => {
    const response = await uploadDocumentsInChunks({
      files,
      customerId: customer.id,
      user,
    })

    return response.map(({ id, status }) => ({ id, status }))
  }

  const onSaveDocuments = async () => {
    setIsLoadingSubmit(true)
    saveDocuments()
    setModalConfiguration(undefined)
    setIsLoadingSubmit(false)
    setAction(undefined)
    navigate(-1)
  }

  const onRejectBuyer = async (reason) => {
    try {
      setIsLoadingSubmit(true)

      if (!reason) {
        throw new FormValidationError('Por favor ingrese un comentario')
      }

      const fileList = await saveDocuments()

      const onboardingEvent = ONBOARDING_STATES.BACKGROUND_REVIEW.transition
      const eventConfiguration = onboardingEvent.rejectBuyer

      const event = {
        name: eventConfiguration.event,
        data: {
          'fileList:multiUpdate': fileList,
          'comment:comment': reason,
          'wfState:comment': customer.wfState,
          'isPublic:comment': true,
          'uuid:comment': null,
          'role:comment': userRole,
          customerId: Number(customerId),
          'customerId:status': Number(customerId),
          'wfState:status': eventConfiguration.nextState,
          'status:status': eventConfiguration.status,
          'id:assignation': customer.assignationId,
          'uuid:assignation': user.uid,
          'role:assignation': userRole,
          'customerId:assignation': Number(customerId),
        },
      }

      await ApiBuyerRepository.doEvent(customerId, event.name, event.data)

      setIsLoadingSubmit(false)
      navigate(Router.Root)
      setAction(undefined)
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
    } catch (error) {
      console.error(error)
      if (error instanceof ApiError) {
        setAlert({
          label: error.message,
          title: error.title,
          type: error.statusCode === 500 ? 'error' : 'warning',
        })
      } else if (error instanceof FormValidationError) {
        setAlert({
          label: error.message,
          title: 'Error',
          type: 'error',
        })
      } else {
        setAlert({
          label: error.message,
          title: 'Error',
          type: 'error',
        })
      }
    }
    setIsLoadingSubmit(false)
  }

  const onRejectBuyerByBuro = async (reason) => {
    try {
      setIsLoadingSubmit(true)

      if (!reason) {
        throw new FormValidationError('Por favor ingrese un comentario')
      }

      const fileList = await saveDocuments()

      const onboardingEvent = ONBOARDING_STATES.BACKGROUND_REVIEW.transition
      const eventConfiguration = onboardingEvent.rejectBuyerByBuro

      const event = {
        name: eventConfiguration.event,
        data: {
          'fileList:multiUpdate': fileList,
          'comment:comment': reason,
          'wfState:comment': customer.wfState,
          'isPublic:comment': true,
          'uuid:comment': user.uid,
          'role:comment': userRole,
          customerId: Number(customerId),
          'customerId:status': Number(customerId),
          'wfState:status': eventConfiguration.nextState,
          'status:status': eventConfiguration.status,
          'id:assignation': customer.assignationId,
          'uuid:assignation': user.uid,
          'role:assignation': userRole,
          'customerId:assignation': Number(customerId),
        },
      }

      await ApiBuyerRepository.doEvent(customerId, event.name, event.data)

      setIsLoadingSubmit(false)
      navigate(Router.Root)
      setAction(undefined)
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
    } catch (error) {
      console.error(error)
      if (error instanceof ApiError) {
        setAlert({
          label: error.message,
          title: error.title,
          type: error.statusCode === 500 ? 'error' : 'warning',
        })
      } else if (error instanceof FormValidationError) {
        setAlert({
          label: error.message,
          title: 'Error',
          type: 'error',
        })
      } else {
        setAlert({
          label: error.message,
          title: 'Error',
          type: 'error',
        })
      }
    }
    setIsLoadingSubmit(false)
  }

  const onApproveBuyer = async (reason) => {
    try {
      setIsLoadingSubmit(true)

      if (!reason) {
        throw new FormValidationError('Por favor ingrese un comentario')
      }

      const fileList = await saveDocuments()

      const onboardingEvent = ONBOARDING_STATES.BACKGROUND_REVIEW.transition
      const eventConfiguration = onboardingEvent.approveBuyer

      const event = {
        name: eventConfiguration.event,
        data: {
          'fileList:multiUpdate': fileList,
          'comment:comment': reason,
          'wfState:comment': customer.wfState,
          'isPublic:comment': true,
          'uuid:comment': user.uid,
          'role:comment': userRole,
          customerId: Number(customerId),
          'customerId:status': Number(customerId),
          'wfState:status': eventConfiguration.nextState,
          'status:status': eventConfiguration.status,
          'customerId:assignationCreate': Number(customerId),
          'role:assignationCreate': 'bpm.mx-amex-b2b.rsk-hd',
          'id:assignation': customer.assignationId,
          'uuid:assignation': user.uid,
          'role:assignation': userRole,
          'customerId:assignation': Number(customerId),
        },
      }

      await ApiBuyerRepository.doEvent(customerId, event.name, event.data)

      setIsLoadingSubmit(false)
      navigate(Router.Root)
      setAction(undefined)
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
    } catch (error) {
      console.error(error)
      if (error instanceof ApiError) {
        setAlert({
          label: error.message,
          title: error.title,
          type: error.statusCode === 500 ? 'error' : 'warning',
        })
      } else if (error instanceof FormValidationError) {
        setAlert({
          label: error.message,
          title: 'Error',
          type: 'error',
        })
      } else {
        setAlert({
          label: error.message,
          title: 'Error',
          type: 'error',
        })
      }
    }
    setIsLoadingSubmit(false)
  }

  const handleOpenModal = (action) => {
    try {
      if (action === 'submit') {
        action = validateDocuments()
      }

      switch (action) {
        case 'saveDocuments':
          setModalConfiguration({
            title: 'Está guardando los documentos',
            message: `Esta acción guardará los documentos y podrá retomar
            el proceso en otro momento. ¿Está seguro de continuar?`,
            action: onSaveDocuments,
            requireReason: false,
          })
          break
        case 'reject':
          setModalConfiguration({
            title: 'Está rechazando al cliente',
            message: `Esta acción rechazará al cliente y no
              podrá retomar el proceso. ¿Está seguro de continuar?`,
            action: onRejectBuyer,
            requireReason: true,
          })
          break
        case 'rejectBuro':
          setModalConfiguration({
            title: 'Está rechazando al cliente',
            message: `Esta acción rechazará al cliente y
              podrá retomar el proceso. ¿Está seguro de continuar?`,
            action: onRejectBuyerByBuro,
            requireReason: true,
          })
          break
        case 'approve':
          setModalConfiguration({
            title: 'Está aprobando al cliente',
            message: `Esta acción aprobará los documentos y enviará
              al prospecto al siguiente paso del proceso. ¿Está seguro
              de continuar?`,
            action: onApproveBuyer,
            requireReason: true,
          })
          break
        default:
          setModalConfiguration(undefined)
      }
    } catch (error) {
      console.error(error)
      if (error instanceof ApiError) {
        setAlert({
          label: error.message,
          title: error.title,
          type: error.statusCode === 500 ? 'error' : 'warning',
        })
      } else if (error instanceof FormValidationError) {
        setAlert({
          label: error.message,
          title: 'Error',
          type: 'error',
        })
      } else {
        setAlert({
          label: error.message,
          title: 'Error',
          type: 'error',
        })
      }
    }
  }

  /* Fetch saved files Effect */
  useEffect(() => {
    try {
      const fetchFiles = async () => {
        const { data: savedFilesData } = await getDocumentList(customerId, {
          stage: FILE_STAGES.RISK,
          status: [
            FILE_STATES.DRAFT,
            FILE_STATES.ACCEPTED,
            FILE_STATES.REFUSED,
          ].join(','),
        })
        let savedFiles = {}
        CREDIT_ANALYSIS.forEach((schema) => {
          let documents = savedFilesData
            .filter((itm) => itm.typeId === schema.typeId)
            .map((itm) => {
              const extension = itm.fileExtension ? `.${itm.fileExtension}` : ''
              return {
                name: `${itm.fileName}${extension}`,
                size: itm.fileSize,
                status: itm.fileStatus,
                id: itm.fileId,
                typeId: itm.typeId,
              }
            })

          savedFiles[schema.typeId] = documents
        })
        setFiles(savedFiles)
      }
      if (customerId) fetchFiles()
    } catch (err) {
      console.error('err', err)
    }
  }, [customerId])

  return (
    <CreditAnalysisStyled>
      <h3>Análisis de riesgo</h3>
      <CustomerDocumentsStyled>
        <BuildSection
          allowUpload
          allowReview
          allowDelete
          allowUpdate
          schema={CREDIT_ANALYSIS}
          title={'Documentos'}
          files={files}
          setFiles={setFiles}
          isValidating={isValidating}
          setCommentBarInfo={setCommentBarInfo}
        />
      </CustomerDocumentsStyled>
      <div className='action-group'>
        <Button onClick={() => navigate(-1)}>Cancelar</Button>
        <div className='actions-flex-end'>
          <Button
            buttonType='outline'
            disabled={isLoadingSubmit}
            onClick={() => handleOpenModal('saveDocuments')}
          >
            {isLoadingSubmit && <LoadingAnimation className='loading' />}
            {!isLoadingSubmit && 'Guardar'}
          </Button>
          <Button
            buttonType='blueDark'
            disabled={isLoadingSubmit}
            onClick={() => handleOpenModal('submit')}
          >
            {isLoadingSubmit && <LoadingAnimation className='loading' />}
            {!isLoadingSubmit && 'Enviar'}
          </Button>
        </div>
      </div>
      {commentBarInfo && (
        <LateralBar
          setLateralBar={setCommentBarInfo}
          documentName={commentBarInfo?.fileLabel}
          fileId={commentBarInfo?.fileId}
        />
      )}

      {modalConfiguration && (
        <ConfirmationModal
          handleCloseModal={() => setModalConfiguration(undefined)}
          modalConfiguration={modalConfiguration}
          handleSubmit={modalConfiguration.action}
          isValidating={false}
        />
      )}
    </CreditAnalysisStyled>
  )
}

const CreditAnalysisStyled = styled.div`
  padding: 1rem 2rem;
  .loading span {
    background-color: white;
  }
  .content {
    display: flex;
  }
  .switch-content {
    .comment {
      line-height: 0px;
      margin: 1rem;
    }
  }
  .switch-div {
    display: flex;
    margin: 0rem 1rem;
  }

  .switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 34px;
    margin: 0.5rem 0rem 0.5rem 0.5rem;
  }

  .switch input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  .slider {
    position: absolute;
    cursor: pointer;
    top: 8px;
    left: 0px;
    right: 14px;
    bottom: 11px;
    background-color: #939191;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  .slider:before {
    position: absolute;
    content: '';
    height: 22px;
    width: 22px;
    left: -1px;
    bottom: -3.5px;
    background-color: #bab9b9;
    -webkit-transition: 0.4s;
    transition: 0.4s;
  }

  input:checked + .slider {
    background-color: #939191;
  }

  input:focus + .slider {
    box-shadow: 0 0 1px #2196f3;
  }

  input:checked + .slider:before {
    -webkit-transform: translateX(26px);
    -ms-transform: translateX(26px);
    transform: translateX(26px);
  }

  .slider.round {
    border-radius: 34px;
  }

  .slider.round:before {
    border-radius: 50%;
  }
  F .documents-risk {
    width: 80%;
  }
  .blBNhs {
    min-width: 60%;
  }
  .textarea {
    padding: 10px;
    font-family: 'Poppins';
    font-weight: bold;
    border-radius: 21px;
    width: 55%;
    height: 108px;
    margin: 1rem 0rem;
    resize: none;

    /* .error {
      border-color: ${({ theme }) => theme.alertTheme.error.backgroundColor};
    } */
  }
  h3 {
    margin: 1rem auto;
  }

  .comment-section {
    margin-block: 1rem;
  }

  .action-group {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 2rem;

    min-width: 300px;

    .actions-flex-end {
      display: flex;
      gap: 10px;
    }
  }

  .row {
    display: flex;
    align-items: center;
    .iScOtA {
      width: 500px;
    }
    .button-section {
      display: flex;
      margin-left: 5%;
      button {
        width: 50px;
        border-radius: 6px;
      }
      button + button {
        margin-left: 15px;
      }
    }
  }
`
const CustomerDocumentsStyled = styled.section`
  .center-loading {
    height: 50vh;
    display: flex;
    align-items: center;
    div {
      width: 150px;
    }
  }
  .checked {
    ${({ theme }) => theme.buttons.outline.hover}
  }
  h3 {
    margin: 1rem auto;
  }

  section {
    width: 65%;
  }
`

export default CreditAnalysis
