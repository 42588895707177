import { Button, LoadingAnimation, Switch } from '@keoworld/gbl-ui-kit'
import ModalUpload from 'containers/Modal/modal-documents-upload'
import { AlertContext } from 'providers/alert'
import { useContext, useEffect, useState } from 'react'
import { RelationRepository } from 'services/relation-supp-cards/relation.repository'
import { getRelationInfo } from 'services/relations'
import styled from 'styled-components'
import { CUSTOMER_SUPPLIER_SOURCES } from 'utils/schemas/customer'

const RelationDetailSupplementary = ({
  line,
  relationId,
  closeLateral,
  fetchRelations,
}) => {
  const { setAlert } = useContext(AlertContext)
  const [relationInfo, setRelationInfo] = useState({})
  const [bppSelector, setBppSelector] = useState(false)
  const [openModalUpload, setOpenModalUpload] = useState(false)
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const [editing, setEditing] = useState(false)
  const {
    identifier,
    lineId,
    providerId,
    cardProgram,
    status,
    days,
    dueFromApproval,
    percentageReceiver,
    providerNid,
    providerSource,
    commerceCode,
    externalId: relationTdcId,
  } = relationInfo

  const onHandleSubmit = async () => {
    try {
      setIsLoadingSubmit(true)
      await RelationRepository.updateCallaoRelationBpp(
        relationTdcId,
        bppSelector
      )
      fetchRelations(lineId)
      closeLateral && closeLateral()
    } catch (error) {
      const errorLabels = {
        default: 'Error al editar la relación',
      }
      setAlert({
        type: 'error',
        title: 'Error<',
        label:
          error.message === 'balance 0 or empty'
            ? errorLabels[error.message]
            : errorLabels[error.title] || error.title || error.default,
      })
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  const onCancelRelation = async () => {
    try {
      setIsLoadingSubmit(true)
      await RelationRepository.cancelRelation(
        relationId,
        relationTdcId,
        relationInfo.providerSource
      )
      fetchRelations(lineId)
      closeLateral && closeLateral()
    } catch (error) {
      setAlert({
        type: 'error',
        title: 'Error',
        label: 'Error al cancelar relación',
      })
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  useEffect(() => {
    const getProvider = async () => {
      const data = await getRelationInfo(relationId)
      setRelationInfo(data)
      setBppSelector(data.bpp)
    }
    if (relationId) {
      getProvider()
    }
  }, [relationId])

  return (
    <RelationDetailStyled>
      {openModalUpload && (
        <ModalUpload
          setOpenModalUpload={setOpenModalUpload}
          messages={{
            msg: '¿Estás seguro que deseas dar de baja al proveedor?',
            msgButton: 'Confirmar',
          }}
          handleSave={onCancelRelation}
          isLoadingSubmit={isLoadingSubmit}
        />
      )}
      <section className='content'>
        <h3>{identifier}</h3>
        <section className='relation-information'>
          <article className='item-inline'>
            <p bold='true'>ID Línea:</p>
            <p>{lineId}</p>
          </article>
          <article className='item-inline'>
            <p bold='true'>ID Proveedor:</p>
            <p>{providerId}</p>
          </article>
          <article className='item-inline'>
            <p bold='true'>ID Relación:</p>
            <p>{relationId}</p>
          </article>
          <article className='item-inline'>
            <p bold='true'>Moneda:</p>
            <p>{line.currency}</p>
          </article>
          <article className='item-inline'>
            <p bold='true'>Fondeador:</p>
            <p>{line.funderName}</p>
          </article>
          <article className='item-inline'>
            <p bold='true'>Card Program:</p>
            <p>{cardProgram}</p>
          </article>
          <article className='item-inline'>
            <p bold='true'>Estado:</p>
            <p>{status}</p>
          </article>
          <article className='item-inline'>
            <p bold='true'>Días:</p>
            <p>{days}</p>
          </article>
          <article className='item-inline'>
            <p bold='true'>Días pactados desde:</p>
            <p>
              {dueFromApproval
                ? 'Días pactados desde la aprobación'
                : 'Días pactados desde la emisión'}
            </p>
          </article>
          <article className='item-inline'>
            <p bold='true'>Porcentaje de cobro de cliente:</p>
            <p>{percentageReceiver}%</p>
          </article>
          <article className='item-inline'>
            <p bold='true'>RFC del proveedor vinculado a la relación:</p>
            <p>{providerNid}</p>
          </article>
          <article className='item-inline'>
            <p bold='true'>Código de comercio vinculado a la relación:</p>
            <p>{commerceCode}</p>
          </article>

          {providerSource === CUSTOMER_SUPPLIER_SOURCES.CALLAO && (
            <article className='item-inline'>
              <label bold='true'>BPP</label>
              <Switch
                checked={bppSelector}
                onChange={() => {
                  if (editing) setBppSelector((prev) => !prev)
                }}
                firstOption='No'
                secondOption='Sí'
              />
            </article>
          )}
        </section>
      </section>
      {status === 'Active' && (
        <section className='btn-group'>
          <Button
            className='cancel-action'
            buttonType='outline'
            onClick={() => {
              if (editing) setOpenModalUpload(true)
            }}
            disabled={isLoadingSubmit || !editing}
          >
            {isLoadingSubmit && <LoadingAnimation />}
            {!isLoadingSubmit && <>Dar de baja</>}
          </Button>

          {!editing && (
            <Button
              buttonType='blueDark'
              onClick={() => {
                setEditing(true)
              }}
            >
              Editar
            </Button>
          )}

          {editing && providerSource === CUSTOMER_SUPPLIER_SOURCES.CALLAO && (
            <Button
              buttonType='blueDark'
              onClick={() => {
                onHandleSubmit()
              }}
              disabled={isLoadingSubmit}
            >
              {!isLoadingSubmit && <>Guardar</>}
              {isLoadingSubmit && <LoadingAnimation />}
            </Button>
          )}
        </section>
      )}
    </RelationDetailStyled>
  )
}

const RelationDetailStyled = styled.aside`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding-bottom: 2rem;

  .relation-information {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .item-inline {
    display: flex;
    justify-content: space-between;
    align-items: baseline;
    gap: 5px;

    p {
      margin: 0;
      max-width: 50%;
      text-wrap: balance;
    }

    p + * {
      text-align: end;
    }
  }

  .content {
    margin-block: 1rem;
    margin-bottom: 2rem;
  }

  .btn-group {
    display: flex;
    justify-content: space-between;

    button {
      height: 40px;
    }
    .cancel-action:not(:hover),
    .cancel-action:disabled {
      background-color: transparent;
    }
  }
`

export default RelationDetailSupplementary
