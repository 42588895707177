import { CustomerContext } from 'providers/customer'
import { DebtCapacityApiService } from '../services/DebtCapacityApi.service'
import DebtCapacityInformation from '../components/debt-capacity-information'
import styled from 'styled-components'
import { useContext, useEffect, useState } from 'react'
import { Skeleton } from '@mui/material'
import { dateFormatFromIso } from 'utils/functions/date'

const DebtCapacitiesHistory = () => {
  const [isFetching, setIsFetching] = useState(true)
  const [history, setHistory] = useState([])

  const { customer } = useContext(CustomerContext)

  useEffect(() => {
    ;(async function () {
      const customerId = customer?.id
      if (!customerId) return
      setIsFetching(true)
      const data = await DebtCapacityApiService.fetchProposalHistory(customerId)
      setHistory(data)
      setIsFetching(false)
    })()
  }, [customer])

  return (
    <StyledContainer>
      {isFetching && (
        <Skeleton variant='rounded' height='300px' width='500px' />
      )}
      {!isFetching &&
        history.map((proposal) => {
          const { proposalId, debtCapacities, createdAt } = proposal
          return (
            <proposal key={proposalId}>
              <h3>ID: {proposalId}</h3>
              <h4>
                Fecha de creación: {dateFormatFromIso(createdAt, 'dd-MM-yyyy')}
              </h4>
              {debtCapacities.map((debtCapacity) => (
                <DebtCapacityInformation
                  key={debtCapacity.id}
                  debtCapacity={debtCapacity}
                />
              ))}
            </proposal>
          )
        })}
    </StyledContainer>
  )
}

const StyledContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1rem;

  proposal {
    background-color: #f6f8fc;
    border-radius: 5px;

    padding: 1rem;
  }
`

export default DebtCapacitiesHistory
