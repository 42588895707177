import axios from 'axios'
import { CUSTOMER_SUPPLIER_SOURCES } from 'utils/schemas/customer'

const KEO_RELATIONS = 'keo-relations'
const CALLAO_RELATIONS = 'callao-relations'

/**
 * Repository class for managing relations.
 */
export class RelationRepository {
  /**
   * Gets the headers for API requests.
   * @returns {Object} The headers object.
   * @private
   */

  /**
   * Creates a Callao relation.
   * @param {Object} relation - The relation object.
   * @returns {Promise} The promise that resolves to the action results.
   */
  static async createCallaoRelation(relation) {
    try {
      await axios.post(`/callao-relations`, {
        instanceId: relation.id,
      })
    } catch (error) {
      console.log('Callao relation workflow instance already exists')
    }
    const response = await axios.put(
      `/callao-relations/${relation.id}/create`,
      {
        'id:relation': relation.id,
        'id:card': relation.supplementaryCardId,
        lineId: relation.lineId,
        paymentMethodId: relation.paymentMethodId,
        supplierCommerceCode: relation.supplierCommerceCode,
        supplierId: relation.supplierId,
        buyerId: relation.buyerId,
        relationId: relation.id,
        cardProgramName: relation.cardProgramName,
        days: relation.days,
        dueFromApproval: relation.dueFromApproval,
        daysType: relation.daysType,
        percentageReceiver: relation.percentageReceiver,
        isBpp: relation.isBpp,
        pathParams: {
          id: relation.id,
        },
      }
    )
    const { actionResults } = response.data.body

    return actionResults
  }

  /**
   * Creates a Keo relation.
   * @param {Object} relation - The relation object.
   * @returns {Promise} The promise that resolves to the action results.
   */
  static async createKeoRelation(relation) {
    try {
      await axios.post(`/keo-relations`, {
        instanceId: relation.id,
      })
    } catch (error) {
      console.log('Keo relation workflow instance already exists')
    }
    const response = await axios.put(`/keo-relations/${relation.id}/create`, {
      'id:relation': relation.id,
      'id:card': relation.supplementaryCardId,
      lineId: relation.lineId,
      paymentMethodId: relation.paymentMethodId,
      supplierCommerceCode: relation.supplierCommerceCode,
      supplierId: relation.supplierId,
      buyerId: relation.buyerId,
      relationId: relation.id,
      cardProgramName: relation.cardProgramName,
      pathParams: {
        relationId: relation.id,
        id: relation.id,
      },
    })
    const { actionResults } = response.data.body

    return actionResults
  }

  /**
   * Updates the Callao relation BPP.
   * @param {string} relationId - The uuid TDC ID of the relation to update.
   * @returns {Promise} The promise that resolves to the action results.
   */
  static async updateCallaoRelationBpp(relationId, isBpp) {
    const response = await axios.put(
      `/callao-relations/${relationId}/update-bpp`,
      {
        pathParams: { relationId },
        isBpp,
      }
    )
    const { actionResults } = response.data.body

    return actionResults
  }

  /**
   * Cancels a relation.
   *
   * @static
   * @async
   * @memberof RelationRepository
   * @param {number} relationSeqId - The sequence ID of the relation.
   * @param {number} relationTdcId - The uuid TDC ID of the relation.
   * @param {string} providerSource - The source of the provider
   * @returns {Promise<{ actionResults: any }>} - A promise that resolves with an object containing the action results.
   */
  static async cancelRelation(relationSeqId, relationTdcId, providerSource) {
    const { decision, processType } =
      await RelationRepository.getRelationCancelDecision(
        relationSeqId,
        relationTdcId,
        providerSource
      )

    const response = await axios.put(
      `/${processType}/${relationTdcId}/${decision}`,
      {
        pathParams: { relationId: relationTdcId },
      }
    )
    const { actionResults } = response.data.body
    return actionResults
  }

  /**
   * Retrieves the cancel decision for a relation.
   *
   * @static
   * @async
   * @memberof RelationRepository
   * @param {number} relationSeqId - The sequence ID of the relation.
   * @param {string} relationTdcId - The TDC ID uuid of the relation.
   * @returns {Promise<{ processType: string, decision: string }>} - A promise that resolves with an object containing the process type and the cancel decision.
   */
  static async getRelationCancelDecision(
    relationSeqId,
    relationTdcId,
    providerSource
  ) {
    let processType =
      providerSource === CUSTOMER_SUPPLIER_SOURCES.CALLAO
        ? CALLAO_RELATIONS
        : KEO_RELATIONS

    const decisionResponse = await axios.put(
      `/${processType}/${relationTdcId}/decide-cancelation`,
      { relationId: relationSeqId }
    )

    const { actionResults } = decisionResponse?.data.body
    const decision = actionResults[0]?.response?.action
    return { processType, decision }
  }
}
