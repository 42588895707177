import { Icon } from '@keoworld/gbl-ui-kit'
import { LoadingButton } from '@mui/lab'
import { ButtonGroup } from '@mui/material'
import Form from 'containers/form'
import LateralBar from 'containers/lateral-bar'
import { AlertContext } from 'providers/alert'
import { CustomerContext } from 'providers/customer'
import { LATERAL_MENU_OPTIONS, LateralBarContext } from 'providers/lateral-bar'
import { useContext, useEffect, useState } from 'react'
import { useNavigate } from 'react-router-dom'
import { ApiBuyerRepository } from 'services/buyer-suppcards/buyer.repository'
import { ApiDebtCapacityRepository } from 'services/debt-capacity-suppcards/debtCapacity.repository'
import { convertObjectFilesToArray } from 'services/documents'
import { financeFind } from 'services/lines'
import styled from 'styled-components'
import RejectReviewModal from 'templates/actions/committee-actions/reject-review-modal'
import { BuildSection } from 'templates/actions/document-actions/build-section'
import { currency } from 'utils/functions/formatters'
import { getUserRoles } from 'utils/functions/role-manager'
import { useAuth } from 'utils/hooks/auth'
import { useFetchDocuments } from 'utils/hooks/fetch-documents'
import Router from 'utils/router'
import { CREDIT_STUDY, CREDIT_STUDY_HEAD } from 'utils/schemas/customer'
import {
  COMMITTEE_SEND,
  FILE_STAGES,
  FILE_STATES,
  FINANCIAL_ANALYSIS,
  VISIT_AND_COMMITTEE_REGISTER,
} from 'utils/schemas/documents'
import { FormValidationError } from 'utils/schemas/errors'
import { ONBOARDING_STATES } from 'utils/schemas/workflows/onboarding'
import DebtCapacity from '../../../containers/debt-capacity/debt-capacity'

const FILE_STAGE_VISIT = { stage: FILE_STAGES.VISIT }
const FILE_STAGE_RISK = { stage: FILE_STAGES.RISK }
const FILE_STAGE_CREDIT = { stage: FILE_STAGES.CREDIT }

const disableSchema = (schema) => {
  return schema.map((itm) => ({ ...itm, disabled: true }))
}

const FORM_SCHEMAS = {
  SOURCE: disableSchema(CREDIT_STUDY_HEAD),
  FINANCIAL: disableSchema(CREDIT_STUDY),
}

const VisitAndCommitteeReview = () => {
  const { setAlert } = useContext(AlertContext)
  const { setAction, setSelectedOption } = useContext(LateralBarContext)
  const { customer } = useContext(CustomerContext)
  const navigate = useNavigate()
  const { user } = useAuth()
  const [userRole] = getUserRoles()

  const visitDocuments = useFetchDocuments(customer?.id, FILE_STAGE_VISIT)
  const legalRiskDocuments = useFetchDocuments(customer?.id, FILE_STAGE_RISK)
  const creditReviewDocuments = useFetchDocuments(
    customer?.id,
    FILE_STAGE_CREDIT
  )

  const [isLoading, setIsLoading] = useState(false)
  const [commentBarConfiguration, setCommentBarConfiguration] = useState()
  const [financeForm, setFinanceForm] = useState({})
  const [financialDocuments, setFinancialDocuments] = useState([])
  const [debtCapacities, setDebtCapacities] = useState({
    debtCapacities: [],
    proposalId: undefined,
  })
  const [modalConfiguration, setModalConfiguration] = useState()
  const [files, setFiles] = useState({})

  const currentWorkflowState = customer?.wfState
  const { requestCorrections, rejectBuyer, approveBuyer } =
    ONBOARDING_STATES.COMMITTEE_REVIEW.events

  const openRejectModal = (configuration) => {
    setModalConfiguration(configuration)
  }

  const validateDocuments = () => {
    const documentsSchema = VISIT_AND_COMMITTEE_REGISTER
    const areThereError = documentsSchema.some(({ typeId }) => {
      return files[typeId].some((file) => file.status !== FILE_STATES.ACCEPTED)
    })

    if (areThereError) {
      throw new FormValidationError(
        `Hay documentos que no han sido aceptados,
          por favor revisa los documentos marcados en rojo`
      )
    }
  }

  const sendToCommittee = async (reasonMessage) => {
    try {
      setIsLoading(true)

      const filesRejected = convertObjectFilesToArray(files).map((file) => ({
        id: file.id,
        status:
          !file?.status || file.status === FILE_STATES.DRAFT
            ? FILE_STATES.REFUSED
            : file.status,
      }))

      await ApiBuyerRepository.doEvent(customer.id, requestCorrections, {
        'fileList:multiUpdate': filesRejected,
        'customerId:status': customer.id,
        'wfState:status': 'COMMITTEE',
        'uuid:assignation': user.uid,
        'customerId:assignation': customer.id,
        'id:assignation': customer.assignationId,
        'role:assignation': userRole,
        'id:proposal': debtCapacities?.proposalId,
        'comment:comment': reasonMessage,
        'wfState:comment': customer?.wfState,
        'isPublic:comment': true,
        'uuid:comment': user?.uid,
        'role:comment': userRole,
      })

      navigate(Router.Root)
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
      setAction(undefined)
    } catch (error) {
      if (error instanceof FormValidationError) {
        setAlert({
          label: error.message,
          title: 'Error',
          type: 'error',
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  const rejectDebtCapacity = async (reasonMessage) => {
    try {
      setIsLoading(true)

      const filesRejected = convertObjectFilesToArray(files).map((file) => ({
        id: file.id,
        status: file.status ?? FILE_STATES.REFUSED,
      }))

      await ApiBuyerRepository.doEvent(customer.id, rejectBuyer, {
        'fileList:multiUpdate': filesRejected,
        'customerId:status': customer.id,
        'wfState:status': 'BUYER_REJECTED',
        'status:status': 'Rejected',
        'uuid:assignation': user.uid,
        'customerId:assignation': customer.id,
        'id:assignation': customer.assignationId,
        'role:assignation': userRole,
        'id:proposal': debtCapacities?.proposalId,
        'comment:comment': reasonMessage,
        'wfState:comment': customer?.wfState,
        'isPublic:comment': true,
        'uuid:comment': user?.uid,
        'role:comment': userRole,
      })

      navigate(Router.Root)
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
      setAction(undefined)
    } catch (error) {
      console.log(error)
      if (error instanceof FormValidationError) {
        setAlert({
          label: error.message,
          title: 'Error',
          type: 'error',
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  const approveDebtCapacity = async (reasonMessage) => {
    try {
      setIsLoading(true)

      validateDocuments()

      const filesApproved = convertObjectFilesToArray(files).map((file) => ({
        id: file.id,
        status: FILE_STATES.ACCEPTED,
      }))

      const NEXT_STATE = 'CONTRACT_SIGNED'

      await ApiBuyerRepository.doEvent(customer.id, approveBuyer, {
        'fileList:multiUpdate': filesApproved,
        'customerId:status': customer.id,
        'wfState:status': NEXT_STATE,
        'uuid:assignation': user.uid,
        'customerId:assignation': customer.id,
        'id:assignation': customer.assignationId,
        'role:assignation': userRole,
        'id:proposal': debtCapacities?.proposalId,
        'comment:comment': reasonMessage,
        'wfState:comment': customer?.wfState,
        'isPublic:comment': true,
        'uuid:comment': user?.uid,
        'role:comment': userRole,
      })

      navigate(Router.Root)
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
      setAction(undefined)
    } catch (error) {
      if (error instanceof FormValidationError) {
        setAlert({
          label: error.message,
          title: 'Error',
          type: 'error',
        })
      }
    } finally {
      setIsLoading(false)
    }
  }

  useEffect(() => {
    const documents = [...legalRiskDocuments, ...creditReviewDocuments]
    const savedFiles = documents.reduce((acc, itm) => {
      const fileTypeDocs = acc[itm.typeId] || []
      const extension = itm.fileExtension ? `.${itm.fileExtension}` : ''
      fileTypeDocs.push({
        name: `${itm.fileName}${extension}`,
        size: itm.fileSize,
        status: itm.fileStatus,
        id: itm.fileId,
        fileExtension: itm.fileExtension,
      })
      return { ...acc, [itm.typeId]: fileTypeDocs }
    }, {})

    const visit = visitDocuments.reduce((acc, itm) => {
      const fileTypeDocs = acc[itm.typeId] || []
      const extension = itm.fileExtension ? `.${itm.fileExtension}` : ''
      fileTypeDocs.push({
        name: `${itm.fileName}${extension}`,
        size: itm.fileSize,
        status: itm.fileStatus,
        id: itm.fileId,
        fileExtension: itm.fileExtension,
      })
      return { ...acc, [itm.typeId]: fileTypeDocs }
    }, {})

    setFiles(visit)
    setFinancialDocuments(savedFiles)
  }, [legalRiskDocuments, creditReviewDocuments, visitDocuments])

  useEffect(() => {
    const fetchDebtCapacities = async () => {
      const { debtCapacities, proposalId } =
        await ApiDebtCapacityRepository.getProposalByBuyerId(customer?.id)
      setDebtCapacities({ debtCapacities, proposalId })
    }
    fetchDebtCapacities()
  }, [customer?.id, currentWorkflowState])

  useEffect(() => {
    const getFinanceOptions = async () => {
      const schemas = [...CREDIT_STUDY_HEAD, ...CREDIT_STUDY]
      const [finance] = await financeFind(customer.id)

      const formattedObject = schemas.reduce((acc, curr) => {
        const { name, validatorType } = curr
        let value = finance[name]
        if (validatorType === 'currency') {
          value = currency(value)
        }
        return { ...acc, [name]: value }
      }, {})

      setFinanceForm(formattedObject)
    }

    if (customer?.id) getFinanceOptions()
  }, [customer?.id])

  return (
    <Styles>
      <section className='visit-upload'>
        <BuildSection
          allowComment
          allowReview
          allowUpload={false}
          isValidating={false}
          files={files}
          setFiles={setFiles}
          title='Documentos de la visita y comité'
          schema={VISIT_AND_COMMITTEE_REGISTER}
          setCommentBarInfo={setCommentBarConfiguration}
        />
      </section>

      <section className='introduction'>
        <BuildSection
          allowComment
          allowUpload={false}
          files={financialDocuments}
          title={COMMITTEE_SEND.title}
          schema={COMMITTEE_SEND.documents}
          setCommentBarInfo={setCommentBarConfiguration}
        />

        <DebtCapacity debtCapacities={debtCapacities.debtCapacities} />
      </section>

      <h5>Indicadores financieros</h5>
      <section className='source-form'>
        <Form
          schema={FORM_SCHEMAS.SOURCE}
          formValues={financeForm}
          isValidating={false}
        />
      </section>

      <section className='financial-form'>
        <Form
          schema={FORM_SCHEMAS.FINANCIAL}
          formValues={financeForm}
          isValidating={false}
        />
      </section>

      <BuildSection
        className='financial-documents'
        allowComment
        allowUpload={false}
        files={financialDocuments}
        isValidating={false}
        schema={FINANCIAL_ANALYSIS.generalInformation.documents}
        setCommentBarInfo={setCommentBarConfiguration}
        title={FINANCIAL_ANALYSIS.generalInformation.title}
      />

      <section className='financial-actions'>
        <LoadingButton
          className='back-button'
          variant='outlined'
          loading={isLoading}
          onClick={() => {
            navigate(-1)
            setAction(undefined)
          }}
        >
          Regresar
        </LoadingButton>

        <ButtonGroup>
          <LoadingButton
            className='corrections-button'
            variant='outlined'
            loading={isLoading}
            onClick={() =>
              !isLoading &&
              openRejectModal({
                title: 'Está solicitando correcciones',
                message:
                  '¿Estás seguro de solicitar correcciones en los documentos? ' +
                  'Después de esta acción podrá retomar el proceso',
                action: sendToCommittee,
                requireReason: true,
              })
            }
          >
            Solicitar correcciones
          </LoadingButton>
          <LoadingButton
            className='reject-button'
            variant='outlined'
            loading={isLoading}
            onClick={() =>
              !isLoading &&
              openRejectModal({
                title: 'Esta rechazando la propuesta de negocio',
                message: `¿Estás seguro de rechazar la propuesta de negocio?
                  Después de esta acción no podrá retomar el proceso.`,
                action: rejectDebtCapacity,
                requireReason: true,
              })
            }
          >
            Rechazar
          </LoadingButton>
          <LoadingButton
            className='approve-button'
            variant='contained'
            endIcon={<Icon name='arrow_forward' />}
            loading={isLoading}
            onClick={() =>
              !isLoading &&
              openRejectModal({
                title: 'Esta aprobando la propuesta de negocio',
                message: `Por favor ingrese la razón por la cual
                 está aprobando la propuesta de negocio`,
                action: approveDebtCapacity,
                requireReason: true,
                icon: 'check_circle',
              })
            }
          >
            Aprobar
          </LoadingButton>
        </ButtonGroup>
      </section>

      {commentBarConfiguration && (
        <LateralBar
          fileId={commentBarConfiguration.fileId}
          documentName={commentBarConfiguration.fileName}
          setLateralBar={setCommentBarConfiguration}
        />
      )}
      {modalConfiguration && (
        <RejectReviewModal
          handleCloseModal={() => setModalConfiguration(undefined)}
          modalConfiguration={modalConfiguration}
          handleSubmit={modalConfiguration.action}
        />
      )}
    </Styles>
  )
}

const Styles = styled.main`
  margin-block: 20px;
  padding-inline: 30px;

  .introduction {
    margin-block: 20px;
  }

  .source-form,
  .financial-form {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
    gap: 10px;

    margin-block: 10px;
  }

  .financial-documents {
    max-width: 800px;
    margin-block: 30px;
  }

  .financial-actions {
    margin-block: 30px;

    display: flex;
    justify-content: space-between;

    .back-button {
      color: #ff0045;
      border-color: #ff0045;
    }

    .corrections-button {
      color: #00172d;
      border-color: #00172d;
    }

    .reject-button {
      background-color: #ff0045;
      border-color: #ff0045;
      color: white;
    }

    .approve-button {
      background-color: #00172d;
      border-color: #00172d;
      color: white;
    }
  }
`

export default VisitAndCommitteeReview
