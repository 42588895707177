import { Button, LoadingAnimation, Select } from '@keoworld/gbl-ui-kit'
import { useContext, useEffect, useState } from 'react'
import styled from 'styled-components'
import { v4 as UUIDv4 } from 'uuid'

import LineConditions, {
  initialLineConditionsForm as initialForm,
  verifyErrors,
} from 'containers/line-conditions/line-conditions'
import { AlertContext } from 'providers/alert'
import { CustomerContext } from 'providers/customer'
import { getCustomerInfo } from 'services/customer'
import { ApiLineRepository } from 'services/line.repository'
import { getContract } from 'services/lines'
import { getCategoryList } from 'services/relations'
import { extractNumberPercentage } from 'utils/functions/handle-percentage'
import { extractNumber } from 'utils/functions/handlers-currency'
import { useReassessmentValidation } from 'utils/hooks/reassessment-validation'
import { FUNDERS } from 'utils/schemas/credit-line'
import {
  ApiDebtCapacityRepository,
  PROPOSAL_STATUS,
} from 'services/debt-capacity-suppcards/debtCapacity.repository'
import { ApiError, FormValidationError } from 'utils/schemas/errors'
import { getProducts } from 'services/lines'

const CreateLine = (props) => {
  const { getLines, setLateralBar } = props

  const [primaryCardPrograms, setPrimaryCardPrograms] = useState([])
  const [lineForm, setLineForm] = useState(initialForm)
  const [lineFormErrors, setLineFormErrors] = useState(initialForm)
  const [isValidating, setIsValidating] = useState(false)
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const [contracts, setContracts] = useState([])
  const [customerCurrencies, setCustomerCurrencies] = useState([])
  const [currency, setCurrency] = useState('')
  const [products, setProducts] = useState([])

  const { customer, setCustomer } = useContext(CustomerContext)
  const { setAlert } = useContext(AlertContext)

  const hasReassessmentInProgress = useReassessmentValidation()

  const validateLineCreation = () => {
    const isThereError = verifyErrors(lineFormErrors)

    if (hasReassessmentInProgress) {
      throw new FormValidationError(
        `El cliente se encuentra en reassessment.
        Finalicé el proceso antes de crear una linea`
      )
    }

    if (
      isThereError ||
      !lineForm.line.currency ||
      !lineForm.line.funder ||
      !lineForm.line.product
    ) {
      throw new FormValidationError(`Complete el formulario`)
    }

    if (
      products.find((product) => product.id === lineForm.line.product)
        ?.useI2C &&
      !lineForm.line.cardProgram
    ) {
      throw new FormValidationError(`Complete el formulario`)
    }
  }

  const onHandleCreate = async () => {
    try {
      setIsValidating(true)
      setIsLoadingSubmit(true)

      validateLineCreation()

      const {
        line: lineConditions,
        ordinary,
        moratorium,
        commission,
      } = lineForm

      const [contract] = contracts

      const lineConditionsCreate = {
        id: UUIDv4(),
        lineConditionId: UUIDv4(),
        primaryCardId: UUIDv4(),
        contractId: contract.externalId,
        buyerId: customer.externalId,
        amount: extractNumber(lineConditions.score),
        maxTerm: Number(lineConditions.maxTerm),
        currency: lineConditions.currency,
        productId: lineForm.line.product,
        funder: lineForm.line.funder,
        terms: [
          ...ordinary.map((period) => {
            return {
              id: UUIDv4(),
              name: period.name,
              rate: extractNumberPercentage(period.rate),
              term: Number(period.term),
              typeId: Number(period.typeId),
              isDelinquency: false,
            }
          }),
          ...moratorium.map((period) => {
            return {
              id: UUIDv4(),
              name: period.name,
              rate: extractNumberPercentage(period.rate),
              typeId: Number(period.typeId),
              isDelinquency: true,
            }
          }),
        ],
        commissions: commission.map((commission) => {
          return {
            id: UUIDv4(),
            name: commission.name,
            amount: extractNumber(commission.amount),
            days: commission.days.split(',').map(Number),
          }
        }),
      }

      const product = products.find(
        (product) => product.id === lineForm.line.product
      )

      if (product?.useI2C) {
        lineConditionsCreate.cardProgram = lineForm.line.cardProgram
      }

      await ApiLineRepository.create(lineConditionsCreate)

      await getLines()
      setLateralBar(false)
    } catch (error) {
      if (error instanceof FormValidationError) {
        setAlert({
          title: '¡Ups! Algo salió mal',
          label: error.message,
          type: 'error',
        })

        return
      }

      if (error instanceof ApiError) {
        setAlert({
          title: error.title,
          label: error.message,
          type: 'error',
        })

        return
      }

      setAlert({
        title: '¡Ups! Algo salió mal',
        label: `Ocurrió en error en la creación de la linea,
          por favor contacte a soporte`,
        type: 'error',
      })
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  useEffect(() => {
    const fetchApprovedProposals = async () => {
      const proposals = await ApiDebtCapacityRepository.getProposalByBuyerId(
        customer.id,
        PROPOSAL_STATUS.APPROVED
      )

      setCustomerCurrencies(
        proposals.debtCapacities.map((debtCapacity) => debtCapacity.currency)
      )
    }

    fetchApprovedProposals()
  }, [customer?.id])

  useEffect(() => {
    const fetchBuyer = async () => {
      const { data: buyer } = await getCustomerInfo(customer?.id)
      setCustomer((prev) => ({ ...prev, ...buyer }))
    }

    if (customer?.id) fetchBuyer()
  }, [customer?.id, setCustomer])

  useEffect(() => {
    const obtainCardPrograms = async () => {
      const categories = await getCategoryList('PRIMARY')
      setPrimaryCardPrograms(categories)
    }

    if (customer?.id) {
      obtainCardPrograms()
    }
  }, [customer?.id])

  useEffect(() => {
    const fetchContract = async () => {
      const { contracts } = await getContract(customer.id, 'Active')
      setContracts(contracts)
    }

    if (customer?.id) fetchContract()
  }, [customer?.id])

  useEffect(() => {
    if (currency) {
      const fetchData = async () => {
        const data = await getProducts(currency)
        setProducts(data)
      }
      fetchData()
    }
  }, [currency]);

  const isActiveCardProgramSelector =
    lineForm.line.product &&
    products.find((product) => product.id === lineForm.line.product)?.useI2C

  return (
    <Styles>
      <div className='scroll' id='scroll-create'>
        <h3>Crear Linea</h3>
        <section className='line-form-group'>
          <header className='input-group'>
            {customerCurrencies.length > 0 && (
              <Select
                className='selector'
                label='Seleccione una moneda'
                name='currency'
                value={lineForm.line.currency}
                onChange={(e) => {
                  setLineForm({
                    ...lineForm,
                    line: {
                      ...lineForm.line,
                      currency: e.target.value,
                      product: '',
                      cardProgram: '',
                    },
                  })
                  setCurrency(e.target.value)
                }
                }
                error={
                  isValidating && !lineForm.line.currency && 'Campo Requerido'
                }
              >
                <option value='' disabled>
                  Seleccione una opción
                </option>
                {customerCurrencies.map((currency) => (
                  <option key={currency} value={currency}>
                    {currency}
                  </option>
                ))}
              </Select>
            )}
            <Select
              className='selector'
              label='Seleccione un fondeador'
              name='funder'
              value={lineForm.line.funder}
              defaultValue=''
              onChange={(e) => {
                setLineForm({
                  ...lineForm,
                  line: {
                    ...lineForm.line,
                    funder: e.target.value,
                  },
                })
              }}
              error={isValidating && !lineForm.line.funder && 'Campo Requerido'}
            >
              <option value='' disabled>
                Seleccione un fondeador
              </option>
              {FUNDERS.map((funder) => (
                <option key={funder.id} value={funder.id}>
                  {funder.name}
                </option>
              ))}
            </Select>
            {lineForm.line.currency && (
              <Select
                className='selector'
                label='Seleccione un producto'
                name='product'
                value={lineForm.line.product}
                defaultValue=''
                onChange={(e) => {
                  setLineForm({
                    ...lineForm,
                    line: {
                      ...lineForm.line,
                      product: e.target.value,
                    },
                  })
                }}
                error={
                  isValidating && !lineForm.line.product && 'Campo Requerido'
                }
              >
                <option value='' disabled>
                  Seleccione un producto
                </option>
                {products.filter(
                  (product) => product.currency === lineForm.line.currency
                ).map((product) => (
                  <option key={product.id} value={product.id}>
                    {product.name}
                  </option>
                ))}
              </Select>
            )}
            {isActiveCardProgramSelector && (
              <Select
                className='selector'
                label='Seleccione un card program'
                name='cardProgram'
                value={lineForm.line.cardProgram}
                defaultValue=''
                onChange={(e) => {
                  setLineForm({
                    ...lineForm,
                    line: {
                      ...lineForm.line,
                      cardProgram: e.target.value,
                    },
                  })
                }}
                error={
                  isValidating &&
                  !lineForm.line.cardProgram &&
                  'Campo Requerido'
                }
              >
                <option value='' disabled>
                  Seleccione un card program
                </option>
                {primaryCardPrograms.map(({ id, cardProgram }, i) => {
                  return (
                    <option key={`cardProgram-${id} ${i}`} value={cardProgram}>
                      {cardProgram}
                    </option>
                  )
                })}
              </Select>
            )}
          </header>
          <LineConditions
            withCurrency={false}
            formErrors={lineFormErrors}
            isValidating={isValidating}
            lineForm={lineForm}
            setFormErrors={setLineFormErrors}
            setLineForm={setLineForm}
          />
        </section>

        <section className='buttons'>
          <Button
            className='button-cancel'
            disabled={isLoadingSubmit}
            buttonType='outline'
            onClick={() => setLateralBar(false)}
          >
            Cancelar
          </Button>
          <Button
            buttonType='blueDark'
            disabled={isLoadingSubmit}
            onClick={onHandleCreate}
          >
            {isLoadingSubmit ? <LoadingAnimation /> : 'Crear'}
          </Button>
        </section>
      </div>
    </Styles>
  )
}

const Styles = styled.article`
  height: 90%;
  overflow-y: hidden;
  padding: 0rem 2rem;

  .scroll {
    overflow-y: scroll;
    height: 100%;
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .offer-content {
    button.action span.icon {
      margin: inherit;
    }
  }

  .line-form-group {
    margin-block: 2rem;
  }

  section .input-group {
    display: grid;
    grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));

    .selector {
      max-width: 300px;
    }

    .checkbox-group {
      margin-block: 10px;

      .checkbox-label {
        font-size: 10px;
        opacity: 0.7;
      }
    }
  }

  .buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
    margin-inline: 10px;

    .button-cancel:not(:hover) {
      background-color: transparent;
    }
  }
`

export default CreateLine
