import axios from 'axios'
import { fileFormData } from 'utils/functions/form-data'
import { FILE_STATES } from 'utils/schemas/documents'
import { ENDPOINTS } from './config'
import { ApiError } from 'utils/schemas/errors'

/**
 *
 * @param {FormData} formData
 * @returns
 */
export const uploadDocument = (formData) =>
  axios.post(ENDPOINTS.file.uploadFile, formData)

/**
 *
 * @param {FormData} formData
 * @returns
 */
export const updateDocument = (formData) =>
  axios.patch(ENDPOINTS.file.update, formData)

/**
 *
 * @param {FormData} formData
 * @returns
 */
export const commentCreate = (formData) =>
  axios.post(ENDPOINTS.file.commentCreate, formData)

/**
 *
 * @param {number} fileId
 * @returns
 */
export const commentFind = async (fileId) => {
  const params = { fileId }
  const { data } = await axios.get(ENDPOINTS.file.commentFind, { params })
  return data
}

/**
 *
 * @param {number} id - Document id
 * @returns
 */
export const downloadDocument = (id) => `/file.download?id=${id}`

/**
 *
 * @param {number} customerId
 * @param {object} filters
 * @returns
 */
export const getDocumentList = (customerId, filters = {}) =>
  axios.get(ENDPOINTS.file.list, { params: { customerId, ...filters } })

/**
 *
 * @param {number} customerId
 * @param {string} status
 * @param {string} role
 * @returns
 */
export const getFileRequest = (customerId, status, role) =>
  axios.get(ENDPOINTS.file.fileRequest, {
    params: { customerId, status, role },
  })

/**
 * service to get file request
 * @param {string} customerId - Customer id
 * @returns
 */
export const fileRequestFind = (customerId) =>
  axios.get(ENDPOINTS.file.requestFind, { params: { customerId } })

/**
 *
 * @param {Object} form
 * @param {number} form.id
 * @param {string} form.uuid
 * @param {string} form.role
 * @param {number} form.typeFileId
 * @returns
 */
export const generateContractFiles = (form) =>
  axios.post(ENDPOINTS.file.contractShip, form)

/**
 *
 * @param {File[]} files
 * @param {number} customerId
 * @param {string} userUid
 * @param {string} role
 * @returns {Promise<Object[]>}
 */
export const uploadDocuments = async (files, customerId, userUid, role) => {
  const filesToUpload = Object.entries(files).reduce((acc, curr) => {
    const [typeFileId, documents] = curr
    const docs = documents.map((file) => {
      if (file instanceof File || file.status === FILE_STATES.DELETED) {
        return fileFormData(file, customerId, typeFileId, userUid, role)
      }
      return { ...file, typeFileId }
    })
    return [...acc, ...docs]
  }, [])

  const chunkSize = 5
  let results = []

  for (let i = 0; i < filesToUpload.length; i += chunkSize) {
    const chunk = filesToUpload.slice(i, i + chunkSize)

    const chunkPromises = chunk.map(async (document) => {
      if (!(document instanceof FormData)) {
        return document
      }
      const response = {
        id: document.get('fileId'),
        name: document.get('name'),
        size: document.get('size'),
        status: document.get('status') || FILE_STATES.DRAFT,
        typeFileId: document.get('typeFileId'),
      }
      if (response.id) {
        await updateDocument(document)
      } else {
        const { data: fileInfo } = await uploadDocument(document)
        response.id = fileInfo.fileId
        response.fileId = fileInfo.fileId
      }
      return response
    })

    const chunkResults = await Promise.allSettled(chunkPromises)
    results = [...results, ...chunkResults]
  }

  return results
}

/**
 * @description Upload documents in chunks
 * @param {Object} props
 * @param {File[]} props.files
 * @param {number} props.customerId
 * @param {Object} props.user
 * @param {Object} configuration
 * @param {number} configuration.chunkSize
 * @returns {Promise<Object[]>}
 */
export const uploadDocumentsInChunks = async (props, configuration) => {
  try {
    const { files, customerId, user } = props
    const { chunkSize = 5 } = configuration ?? {}

    const userUid = user.uid
    const {
      roles: [role],
    } = user.iam

    const filesToUpload = Object.entries(files).reduce((acc, curr) => {
      const [typeFileId, documents] = curr
      const docs = documents.map((file) => {
        if (file instanceof File || file.status === FILE_STATES.DELETED) {
          return fileFormData(file, customerId, typeFileId, userUid, role)
        }
        return { ...file, typeFileId }
      })
      return [...acc, ...docs]
    }, [])

    let results = []

    for (let i = 0; i < filesToUpload.length; i += chunkSize) {
      const chunk = filesToUpload.slice(i, i + chunkSize)

      const chunkPromises = chunk.map(async (document) => {
        if (!(document instanceof FormData)) {
          return document
        }
        const response = {
          id: document.get('fileId'),
          name: document.get('name'),
          size: document.get('size'),
          status: document.get('status') || FILE_STATES.DRAFT,
          typeFileId: document.get('typeFileId'),
        }
        if (response.id) {
          await updateDocument(document)
        } else {
          const { data: fileInfo } = await uploadDocument(document)
          response.id = fileInfo.fileId
          response.fileId = fileInfo.fileId
        }
        return response
      })

      const chunkResults = await Promise.all(chunkPromises)
      results = [...results, ...chunkResults]
    }

    return results
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new ApiError(error.cause, error.message, error.status)
    }
    throw error
  }
}

/**
 * @param {Object} files
 */
export const convertObjectFilesToArray = (files = {}) => {
  const filesToUpload = Object.entries(files).reduce((acc, curr) => {
    const [typeFileId, documents] = curr

    const docs = documents.map((file) => {
      return { ...file, typeFileId }
    })

    return [...acc, ...docs]
  }, [])

  return filesToUpload
}
