/* eslint-disable react-hooks/exhaustive-deps */
import { Button, Icon, LoadingAnimation } from '@keoworld/gbl-ui-kit'
import Chip from 'components/chip'
import PaginatedItems from 'components/pagination'
import CustomerList from 'containers/dashboard/onboarding/customer-list'
import DashboardFilter, {
  ONBOARDING_FILTERS,
} from 'containers/dashboard/onboarding/dashboard-filter'
import ProspectList from 'containers/dashboard/onboarding/prospect-list'
import StageFilter from 'containers/dashboard/onboarding/stage-filter'
import SupplementaryCardsFilter from 'containers/dashboard/onboarding/supplementary-cards-filter'
import { CustomerContext } from 'providers/customer'
import { LATERAL_MENU_OPTIONS, LateralBarContext } from 'providers/lateral-bar'
import { useCallback, useContext, useEffect, useState } from 'react'
import { getCustomers } from 'services/customer'
import styled from 'styled-components'
import { getUserRoles } from 'utils/functions/role-manager'
import { useDebounce } from 'utils/hooks/debounce'
import { CUSTOMER_STATES } from 'utils/schemas/customer'
import { STATE_ROLES } from 'utils/schemas/roles'
import { ONBOARDING_STATES } from 'utils/schemas/workflows/onboarding'
import { useAuth } from 'utils/hooks/auth'

const filterInitialState = {
  numberOfItems: 8,
  page: 0,
  params: [],
}

const OnboardingDashboard = () => {
  const { user } = useAuth();

  const { customer, setCustomer } = useContext(CustomerContext)
  const { setSelectedOption } = useContext(LateralBarContext)
  const [filter, setFilter] = useState()
  const [customers, setCustomers] = useState([])
  const [loading, setLoading] = useState(true)
  const [maxItems, setMaxItems] = useState(0)
  const [role] = getUserRoles()
  const [buyerFlow, setBuyerFlow] = useState('supplementaryCards')

  const filterDebounce = useDebounce(filter, 100)
  const buyerFlowDebounce = useDebounce(buyerFlow, 100)

  const setQueryParameter = (name, value) => {
    const params = filter.params.filter((param) => param.name !== name)

    const filters = {
      ...filter,
      page: 0,
      params: [...params, { name, value }],
    }

    sessionStorage.setItem('onboardingFilter', JSON.stringify(filters))
    setFilter(filters)
  }

  const removeQueryParameter = (name) => {
    const filters = {
      ...filter,
      page: 0,
      params: filter.params.filter((param) => param.name !== name),
    }

    setFilter(filters)
    sessionStorage.setItem('onboardingFilter', JSON.stringify(filters))
  }

  const obtainCustomer = useCallback(async () => {
    try {
      if (!filterDebounce) return

      setLoading(true)
      setCustomers([])

      const { params, page, numberOfItems, stage } = filterDebounce

      const filters = params.reduce((previous, current = {}) => {
        const { name, value } = current
        return { ...previous, [name]: value }
      }, {})

      const query = {
        role,
        userId: user.iam.id,
        page,
        numberOfItems,
        stage,
        ...filters,
      }
      const { customers, fullCount } = await getCustomers(
        query,
        buyerFlowDebounce
      )
      setCustomers(customers)
      setMaxItems(fullCount)
    } catch (error) {
      console.error(error)
    } finally {
      setLoading(false)
    }
  }, [filterDebounce, buyerFlowDebounce])

  useEffect(() => {
    const query = sessionStorage.getItem('onboardingFilter')
    const buyerFlow = sessionStorage.getItem('buyerFlow')

    setBuyerFlow(buyerFlow ?? 'supplementaryCards')

    if (!query) {
      setFilter(filterInitialState)
    } else {
      const onboardingFilter = JSON.parse(query)
      setFilter(onboardingFilter)
    }
  }, [])

  useEffect(() => {
    obtainCustomer()
  }, [obtainCustomer])

  useEffect(() => {
    if (customer) setCustomer(undefined)
  }, [customer])

  const oauthIdToken = localStorage.getItem('oauthIdToken')

  return (
    <CustomerListStyled>
      <SupplementaryCardsFilter
        buyerFlow={buyerFlow}
        setBuyerFlow={(flow) => {
          setBuyerFlow(flow)
          setFilter(filterInitialState)
          sessionStorage.setItem('buyerFlow', flow)
          sessionStorage.setItem(
            'onboardingFilter',
            JSON.stringify(filterInitialState)
          )
        }}
      />
      <StageFilter
        stage={filter?.stage}
        setStage={(stage) => {
          setFilter({ ...filter, stage, page: 0 })
          sessionStorage.setItem(
            'onboardingFilter',
            JSON.stringify({ ...filter, stage, page: 0 })
          )
        }}
      />

      <DashboardFilter
        buyerFlow={buyerFlow}
        setQuerySearch={(filter) => {
          const { name, value } = filter
          setQueryParameter(name, value)
        }}
      />

      {filter && filter.params?.length >= 0 && (
        <div className='chips'>
          {filter.params
            .filter(({ name }) => name !== 'stage')
            .map(({ name, value }, index) => {
              const removeFilter = () => removeQueryParameter(name)

              let chipLabel = `${ONBOARDING_FILTERS[name]}: `

              if (name !== 'state') {
                chipLabel += value
              } else {
                chipLabel +=
                  buyerFlowDebounce === 'supplementaryCards'
                    ? ONBOARDING_STATES[value]?.label
                    : CUSTOMER_STATES[value]?.label
              }

              return (
                <Chip
                  key={`chip-${index}`}
                  className='chip'
                  icon='close'
                  label={chipLabel}
                  onClick={removeFilter}
                />
              )
            })}
        </div>
      )}
      {loading && (
        <div className='center-loading'>
          <LoadingAnimation />
        </div>
      )}
      <div className='table-div'>
        {!loading && (
          <>
            {buyerFlowDebounce === 'supplementaryCards' && (
              <ProspectList
                customers={customers}
                refreshList={obtainCustomer}
              />
            )}
            {buyerFlowDebounce === 'oldFlow' && (
              <CustomerList
                customers={customers}
                refreshList={obtainCustomer}
              />
            )}
            {maxItems > 8 && (
              <div className='pagination'>
                <PaginatedItems
                  currentPage={filter.page}
                  handlePageClick={(event) => {
                    setFilter({ ...filter, page: event.selected })
                    sessionStorage.setItem(
                      'onboardingFilter',
                      JSON.stringify({ ...filter, page: event.selected })
                    )
                  }}
                  pageCount={Math.ceil(maxItems / 8)}
                />
              </div>
            )}
          </>
        )}
      </div>

      {STATE_ROLES.createCustomer.includes(role) && oauthIdToken && (
        <Button
          className='onboarding'
          size='action'
          onClick={() => setSelectedOption(LATERAL_MENU_OPTIONS.ONBOARDING)}
        >
          <Icon name='add' />
        </Button>
      )}
    </CustomerListStyled>
  )
}

const CustomerListStyled = styled.div`
  height: calc(100vh - 70px);
  .center-loading {
    height: 90vh;
    display: flex;
    align-items: center;
    div {
      width: 150px;
    }
  }
  .onboarding {
    position: absolute;
    right: 1rem;
    top: 5rem;
  }
  .table-div {
    overflow-x: auto;
    min-height: calc(100% - 150px);
    padding: 1rem 2rem;
    width: 100%;
    .pagination {
      margin: 1rem auto;
      ul {
        margin: auto;
      }
    }
  }
  .chips {
    display: flex;
    flex-wrap: wrap;

    gap: 10px;

    margin-top: 10px;
    padding: 0 15px;
  }
`

export default OnboardingDashboard
