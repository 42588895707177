import axios from 'axios'
import { ApiError } from 'utils/schemas/errors'
import { ENDPOINTS } from './config'

export class LoanRepository {
  /**
   * fetch the debts projected for a requested date of a loan
   * @param {Object} query - The query object
   * @param {number} query.loanId - The loan id
   * @param {Date} query.date - The payment date to project
   */
  static async fetchLoanProjection(query) {
    try {
      const { loanId, date } = query

      if (!loanId)
        throw new ApiError('LOAN_ID_REQUIRED', 'The loan id is required', 500)
      if (!date || isNaN(new Date(date)))
        throw new ApiError(
          'DATE_REQUIRED',
          'The projection date is required',
          500
        )

      const { data } = await axios.get(ENDPOINTS.loan.projection, {
        params: { loanId, projectionDate: date },
      })

      const { terms, commissions, concepts, totalDebt, repayments } = data

      const conceptsMap = concepts.map((concept) => {
        const { loanConceptId, type } = concept

        switch (type) {
          case 'COMMISSION':
            let commission = commissions.find(
              (commission) => commission.id === loanConceptId
            )
            return {
              ...concept,
              name: `${commission.name} - Día ${commission.day}`,
              commission,
            }
          case 'TERM':
            const term = terms.find((term) => term.id === loanConceptId)
            return { ...concept, name: term.name, term }
          default:
            return { ...concept, name: 'Capital', type: 'CAPITAL' }
        }
      })

      const repaymentsMap = repayments.map((repayment) => {
        return {
          ...repayment,
          charges: repayment.charges.map((charge) => {
            const { conceptId } = charge
            const concept = conceptsMap.find(
              (concept) => concept.id === conceptId
            )

            const { type, term, commission } = concept

            switch (type) {
              case 'COMMISSION':
                return {
                  ...charge,
                  name: `${commission.name} - Día ${commission.day}`,
                }
              case 'TERM':
                return { ...charge, name: term.name }
              default:
                return { ...charge, name: 'Capital' }
            }
          }),
        }
      })

      return {
        total: totalDebt,
        terms,
        commissions,
        concepts: conceptsMap,
        repayments: repaymentsMap,
      }
    } catch (error) {
      if (error instanceof ApiError) throw error
      if (axios.isAxiosError(error)) {
        const { data } = error.response
        throw new ApiError(data.title, data.detail, data.status)
      }
      throw new ApiError('UNKNOWN_ERROR', error.message, 500)
    }
  }
}
