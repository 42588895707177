import { Button, LoadingAnimation } from '@keoworld/gbl-ui-kit'
import CustomerForm from 'containers/customer-form'
import Form from 'containers/form'
import { AlertContext } from 'providers/alert'
import { LATERAL_MENU_OPTIONS, LateralBarContext } from 'providers/lateral-bar'
import { Fragment, useContext, useEffect, useState } from 'react'
import { ApiBuyerRepository } from 'services/buyer-suppcards/buyer.repository'
import { createReferrer } from 'services/customer'
import {
  createAgent,
  createBusiness,
  createLocation,
  createPerson,
} from 'services/shareholder'
import styled from 'styled-components'
import { extractNumberPercentage } from 'utils/functions/handle-percentage'
import { useAuth } from 'utils/hooks/auth'
import {
  CUSTOMER_DB_STATUS,
  CUSTOMER_STAGES,
  initialCustomerForm,
} from 'utils/schemas/customer'
import { ONBOARDING_STATES } from 'utils/schemas/workflows/onboarding'

const Onboarding = () => {
  const { setAlert } = useContext(AlertContext)
  const [formValues, setFormValues] = useState(initialCustomerForm)
  const [formReferrer, setFormReferrer] = useState({})
  const isLoading = false
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const [isValidating, setIsValidating] = useState(false)
  const [formErrors, setFormErrors] = useState({
    ...initialCustomerForm,
    customer: { 'description:customer': true },
    sh_person: {
      areaCode: true,
      civilStatus: true,
      email: true,
      firstSurname: true,
      name: true,
      phone: true,
      secondSurname: true,
      sharePercentage: true,
      tin: true,
    },
    sh_business: {
      areaCode: true,
      email: true,
      mainActivity: true,
      name: true,
      phone: true,
      tin: true,
    },
    sh_location: {
      address: true,
      city: true,
      colony: true,
      externalNumber: true,
      internalNumber: true,
      municipality: true,
      state: true,
      zipCode: true,
    },
    sh_agent: {
      firstSurname: true,
      name: true,
      secondSurname: true,
    },
  })
  const [error, setError] = useState()
  const { setSelectedOption } = useContext(LateralBarContext)
  const { user } = useAuth()

  const onHandleSubmit = async () => {
    try {
      setIsValidating(true)
      setIsLoadingSubmit(true)
      /* Check for errors */
      let hasErrors = false
      const sh_type = formValues.customer?.personType

      const errors = { ...formErrors }

      if (sh_type === 'moralPerson') {
        errors.sh_person = undefined
      } else if (sh_type === 'naturalPerson') {
        errors.sh_business = undefined
        errors.sh_agent = undefined
      }

      Object.values(errors)
        .filter((error) => Boolean(error))
        .forEach((value) => {
          const sectionErrors = Object.values(value).filter(
            (field) => field === true
          )
          if (sectionErrors.length > 0) {
            hasErrors = true
          }
        })

      const referrerError = formReferrer?.referrerId && !formReferrer?.type
      if (!hasErrors && !referrerError) {
        /* shareholder location */
        const { data: location_data } = await createLocation(
          formValues.sh_location
        )
        const { locationId } = location_data

        let shareholderId
        if (sh_type === 'moralPerson' && locationId) {
          /* agent creation */
          const { data: agent_data } = await createAgent({
            ...formValues.sh_agent,
            areaCode: '',
            phone: '',
          })
          const [{ fn_create_agent: agentId }] = agent_data
          /* business creation */
          const { data: business_data } = await createBusiness(
            { ...formValues.sh_business, zipCode: '0000' },
            agentId,
            locationId
          )
          if (business_data) {
            const [{ fn_create_business: businessId }] = business_data
            shareholderId = businessId
          }
        }
        if (sh_type === 'naturalPerson' && locationId) {
          /* person creation */
          const { data: person_data } = await createPerson(
            {
              ...formValues.sh_person,
              sharePercentage: extractNumberPercentage(
                formValues.sh_person.sharePercentage
              ),
            },
            locationId
          )
          if (person_data) {
            const [{ fn_create_person: personId }] = person_data
            shareholderId = personId
          }
        }

        let referrerId
        if (formReferrer?.referrerId) {
          /* referrer creation */
          const { data: referrerData } = await createReferrer(formReferrer)
          referrerId = referrerData.referrerId
        }

        const uuid = user.uid
        const {
          roles: [role],
        } = user.iam

        if (shareholderId) {
          await ApiBuyerRepository.create({
            ...formValues.customer,
            'position:user': process.env.REACT_APP_USER_POSITION,
            'authorizedBy:user': process.env.REACT_APP_USER_AUTHORIZED_BY,
            'wfState:customer': ONBOARDING_STATES.CREATE_PROPOSAL.value,
            'status:customer': CUSTOMER_DB_STATUS.PROSPECT,
            'wfStage:customer': CUSTOMER_STAGES.prospecting.id,
            'referrerId:customer': referrerId,
            'shareholderId:customer': shareholderId,
            'role:assignation': role,
            'uuid:assignation': uuid,
            'isMigrate:customer': true,
          })
        }

        setIsLoadingSubmit(false)
        setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
      }
    } catch (err) {
      setError((prev) => ({ ...prev, upload: err?.message ?? '' }))
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  useEffect(() => {
    if (error) {
      setAlert({
        type: 'error',
        title: 'Error',
        label: error.upload || error.fetch || 'Error al crear prospecto',
      })
    }
  }, [error, setAlert])

  return (
    <OnboardingStyled>
      {isLoading && <LoadingAnimation />}
      {!isLoading && (
        <Fragment>
          <h3 className='title'>Crear Prospecto</h3>
          <div className='referrer'>
            <div className='referrer-form'>
              <Form
                formValues={formReferrer}
                setFormValues={(newFormValues) =>
                  setFormReferrer((prev) => ({ ...prev, ...newFormValues }))
                }
                formErrors={{
                  referrerId: false,
                  type:
                    Boolean(formReferrer?.referrerId) && !formReferrer?.type,
                }}
                isValidating={isValidating}
              />
            </div>
          </div>
          <CustomerForm
            formValues={formValues}
            setFormValues={setFormValues}
            formErrors={formErrors}
            setFormErrors={setFormErrors}
            isValidating={isValidating}
          />
          <div className='action-group'>
            <Button
              buttonType='outlineBlueDark'
              onClick={() =>
                setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
              }
            >
              Cancelar
            </Button>
            <Button
              buttonType='blueDark'
              onClick={onHandleSubmit}
              disabled={isLoadingSubmit}
            >
              {isLoadingSubmit ? <LoadingAnimation /> : 'Continuar'}
            </Button>
          </div>
        </Fragment>
      )}
    </OnboardingStyled>
  )
}

const OnboardingStyled = styled.div`
  padding: 2rem;
  margin: 1em 0;

  .fcDNbZ span {
    background-color: white;
  }

  .action-group {
    margin: 2rem 42px 0;

    button + button {
      margin-left: 28px;
    }
  }

  .referrer {
    display: flex;
    align-items: end;
    margin: 1rem 0;

    .ref-prefix {
      margin: 5px 4px;
      font-weight: 500;
    }
    .referrer-form {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;

      & > div {
        padding: 0 1rem;
        &:nth-child(3n + 1) {
          padding-left: 0;
        }
        &:nth-child(3n) {
          padding-right: 0;
        }
      }

      .textarea {
        grid-column: span 2;
      }
    }
  }
`

export default Onboarding
