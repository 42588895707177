import axios from 'axios'
import { ApiError } from 'utils/schemas/errors'

export class DebtCapacityApiService {
  /**
   * Function to obtain debt capacities in the proposal history
   * @param {Number} customerId The customer id
   * @returns {Promise<{
   *  proposalId: String;
   *  customerId: Number;
   *  status: String;
   *  createdAt: String;
   *  debtCapacities: {}[];
   * }[]>}
   */
  static async fetchProposalHistory(customerId) {
    try {
      const ENDPOINT = '/proposal.getHistoryByCustomer'
      if (!customerId) {
        throw new ApiError(
          'Error al obtener el historial de propuestas',
          'No se ha proporcionado el id del cliente',
          500
        )
      }

      const { data: response } = await axios.get(ENDPOINT, {
        params: { customerId },
      })

      return response.data
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        throw error
      }

      throw new ApiError(
        'Error al obtener el historial de propuestas',
        error.response?.data?.message || error.message,
        error.response?.status || 500
      )
    }
  }
}
