import PropTypes from 'prop-types'
import { Button, Icon } from '@keoworld/gbl-ui-kit'
import { useContext, useId, useRef, useState } from 'react'
import styled from 'styled-components'
import { bytesConverter } from 'utils/functions/bytes-converter'
import { FILE_STATES } from 'utils/schemas/documents'
import { FormValidationError } from 'utils/schemas/errors'
import { AlertContext } from 'providers/alert'

const Document = (props) => {
  const {
    allowUpload = true,
    allowDownload = true,
    allowRemoveDocumentSection = false,
    allowedDocumentTypes = [],
  } = props

  const [isEditing, setIsEditing] = useState()
  const [tooltip, setTooltip] = useState(false)
  const [tooltipTimer, setTooltipTimer] = useState(() => {})
  const inputRef = useRef(null)
  const inputId = useId()

  const { setAlert } = useContext(AlertContext)

  const uploadFiles = () => window.document.getElementById(inputId).click()

  const validateFileExtension = (files) => {
    if (allowedDocumentTypes.length === 0) return

    files.forEach((file) => {
      const { type: fileType, name: fileName } = file
      const [fileGroup, fileExtension] = fileType.split('/')
      const [, fileNameExtension] = fileName.split('.')

      if (!fileNameExtension)
        throw new FormValidationError('Archivo sin extensión', 'fileExtension')

      const isFileValid = allowedDocumentTypes.some((allowedType) => {
        if (allowedType.includes('/')) {
          const [allowedFileGroup, allowedFileExtension] =
            allowedType.split('/')
          if (allowedType === fileType) return true
          if (allowedFileGroup !== fileGroup) return false
          if (allowedFileExtension === '*') return true
          if (allowedFileExtension !== fileExtension) return false
        } else {
          if (allowedType === '*') return true
          if (allowedType !== `.${fileExtension}`) return false
        }
        return true
      })

      if (!isFileValid) {
        throw new FormValidationError('Tipo de archivo no permitido', [
          'allowedDocumentTypes',
          allowedDocumentTypes.join(', '),
        ])
      }
    })
  }

  const onHandleChange = (event) => {
    try {
      const newFiles = []

      let filesToUpload = Array.from(event.target.files)
      let files = props.files

      validateFileExtension(filesToUpload)

      if (isEditing) {
        let [fileToEdit] = filesToUpload
        fileToEdit.id = isEditing.id
        fileToEdit.fileId = isEditing.fileId

        filesToUpload = [fileToEdit]
        files = files.filter((prevFile) => prevFile.name !== isEditing.name)
      }

      filesToUpload.forEach((file) => {
        const exist = files.some((prevFile) => prevFile.name === file.name)
        if (!exist) {
          newFiles.push(file)
        }
      })

      if (typeof props.setFiles === 'function') {
        props.setFiles([...files, ...newFiles])
      }

      setIsEditing(undefined)
    } catch (error) {
      console.error(error.message)
      if (error instanceof FormValidationError) {
        setAlert({
          type: 'error',
          title: 'Error al subir archivo',
          label: error.message,
        })
      }
    }
  }

  const onHandleEdit = (file) => {
    setIsEditing(file)
    uploadFiles()
  }

  const handlerTooltip = () => {
    clearTimeout(tooltipTimer)
    setTooltip(true)
    const timer = setTimeout(() => {
      setTooltip(false)
    }, 2000)
    setTooltipTimer(timer)
  }

  const handleClickReview = (file) => {
    handlerTooltip()
    const index = props.files.indexOf(file)
    if (typeof props.setFiles === 'function' && index !== -1) {
      const files = props.files
      const checkedValue = file.status !== FILE_STATES.ACCEPTED
      files[index].status = checkedValue
        ? FILE_STATES.ACCEPTED
        : FILE_STATES.REFUSED
      props.setFiles(files)
    }
  }

  const onHandleRemove = (file) => {
    const files = props.files
    if (typeof props.setFiles === 'function') {
      if (!file.id) {
        props.setFiles(files.filter((fileData) => fileData.name !== file.name))
      } else {
        props.setFiles(
          files.map((prevFile) => {
            if (prevFile.name === file.name) {
              prevFile.status = FILE_STATES.DELETED
            }
            return prevFile
          })
        )
      }
    }
  }

  return (
    <DocumentStyled className={props.className}>
      <input
        id={inputId}
        ref={inputRef}
        accept={props.allowedDocumentTypes}
        onChange={onHandleChange}
        type='file'
        value=''
        multiple
        hidden
      />
      <div className={`file-section ${props.warning ? 'warning' : ''}`}>
        <div className='upload-docs'>
          <span className='file-type'>{props.label}</span>
          {allowUpload && (
            <Button className='attach-files' onClick={uploadFiles}>
              subir archivos
            </Button>
          )}
          {allowRemoveDocumentSection && (
            <Button
              className='attach-files'
              onClick={() => props.onHandleRemoveDocumentSection()}
            >
              Eliminar sección
            </Button>
          )}
        </div>
        {props.files.length > 0 && (
          <div className='file-list'>
            {props.files
              .filter((file) => file.status !== FILE_STATES.DELETED)
              .map((file, index) => {
                return (
                  <div className='file-chip' key={`file-chip-${index}`}>
                    <div className='file-info'>
                      <div className='file-name' title={file.name}>
                        {file.name}
                      </div>
                      {file.size && (
                        <div className='file-size'>
                          {bytesConverter(file.size)} MB
                          {file.status === FILE_STATES.REFUSED && (
                            <Icon
                              name='report'
                              title='Documento rechazado'
                              type='outlined'
                            />
                          )}
                        </div>
                      )}
                    </div>
                    <div className='file-actions'>
                      {typeof props.onClickDownload === 'function' &&
                        !(file instanceof File) &&
                        file.id &&
                        allowDownload && (
                          <Icon
                            name='download'
                            onClick={() => props.onClickDownload(file)}
                          />
                        )}
                      {typeof props.onClickComment === 'function' &&
                        file.id && (
                          <Icon
                            name='chat'
                            onClick={() => props.onClickComment(file)}
                          />
                        )}
                      {file.status !== FILE_STATES.ACCEPTED &&
                        props.allowUpdate && (
                          <Icon
                            name='edit'
                            onClick={() => onHandleEdit(file)}
                          />
                        )}
                      {file.status !== FILE_STATES.ACCEPTED &&
                        props.allowDelete && (
                          <Icon
                            name='delete'
                            onClick={() => onHandleRemove(file)}
                          />
                        )}
                      {props.allowReview && (
                        <div className='button-section'>
                          <Button
                            className={
                              file.status === FILE_STATES.ACCEPTED
                                ? 'checked'
                                : ''
                            }
                            buttonType='outline'
                            onClick={() => handleClickReview(file)}
                          >
                            <Icon
                              name={
                                file.status === FILE_STATES.ACCEPTED
                                  ? 'check_circle'
                                  : 'cancel'
                              }
                              type='outlined'
                            />
                          </Button>
                          {tooltip && (
                            <div className='tooltip-container'>
                              <span className='tooltipText'>
                                {file.status === FILE_STATES.ACCEPTED
                                  ? 'Aprobado'
                                  : 'Rechazado'}
                              </span>
                            </div>
                          )}
                        </div>
                      )}
                    </div>
                  </div>
                )
              })}
          </div>
        )}
      </div>
    </DocumentStyled>
  )
}

const DocumentStyled = styled.div`
  .file-actions {
    display: flex;
    justify-content: center;
    align-items: center;
    color: #424242 !important;
  }

  .tooltip-container {
    position: relative;
    display: none;
    .tooltipText {
      transform: translate(0.3rem, 0.3rem);
      width: 100px !important;
      background-color: #9b9b9b;
      color: #fff;
      text-align: center;
      border-radius: 3pt;
      padding: 2pt;
      position: absolute;
      z-index: 1;
    }
  }

  .button-section {
    display: flex;
    button {
      display: flex;
      justify-content: center;
      align-items: center;
      border: 2px solid #ff0045;
      color: #ff0045;
      background-color: white;
      margin-left: 0.4rem;
      width: 2.5rem;
      height: 2.2rem;
      border-radius: 5px;
      &.checked {
        ${({ theme }) => theme.buttons.outline.hover}
        background-color: #FFFFFF;
        color: #2ecc71;
        border: 2px solid #2ecc71;
      }
      &:focus ~ .tooltip-container {
        display: block;
      }
    }
    button + button {
      margin-left: 15px;
    }
  }

  .file-section {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    border: 1px solid
      ${({ theme }) => theme.colors.grayShades[300].backgroundColor};
    border-radius: 5px;

    margin-top: 0.5rem;
    padding: 0.5rem 1rem;

    min-height: 50px;

    .upload-docs {
      display: flex;
      align-items: center;
      justify-content: space-between;
      flex-wrap: wrap;
      gap: 5px;
      padding: 0.5rem 0;
      margin-bottom: 1rem;
      width: 100%;
      border-bottom: 1px solid #efefef;
    }

    .file-type {
      align-self: center;
      text-transform: capitalize;
    }

    button.attach-files {
      display: flex;
      align-items: center;
      justify-content: center;
      gap: 5px;
      height: 25px;
      width: auto;
      text-transform: uppercase;
      border-radius: 7px !important;
      padding: 0.7rem;
    }

    &.warning {
      border-color: ${({ theme }) => theme.alertTheme.error.backgroundColor};
    }
  }

  .file-list {
    display: flex;
    gap: 5px;
    width: 100%;
    flex-wrap: wrap;
    margin-bottom: 1rem;
  }

  .color-red {
    color: red !important;
    border: 1px solid red !important;
  }

  .file-chip {
    display: flex;
    justify-content: space-between;
    align-items: center;
    gap: 5px;
    width: 21rem;
    padding: 0.5rem 0.6rem 0.5rem 0.7rem;
    margin-top: 0.5rem;
    border-radius: 7px;
    border: 1px solid #424242;
    color: #424242;

    .file-info {
      margin-right: 10px;
      .file-name {
        text-overflow: ellipsis;
        overflow: hidden;
        white-space: nowrap;
        max-width: 150px;
      }
      .file-size {
        font-size: 0.7em;
        display: flex;
        align-items: center;
        span {
          margin-left: 0.3rem;
          color: red;
          font-size: 1.2rem;
          cursor: text;
        }
      }
    }
  }

  span.icon {
    cursor: pointer;
  }
`

Document.propTypes = {
  allowedDocumentTypes: PropTypes.arrayOf(PropTypes.string),
  allowDelete: PropTypes.bool,
  allowUpdate: PropTypes.bool,
  allowDownload: PropTypes.bool,
  allowReview: PropTypes.bool,
  allowUpload: PropTypes.bool,
  allowRemoveDocumentSection: PropTypes.bool,
  className: PropTypes.string,
  files: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.number,
      name: PropTypes.string,
      size: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
      status: PropTypes.string,
    })
  ),
  label: PropTypes.string,
  onClickComment: PropTypes.func,
  onClickDownload: PropTypes.func,
  onHandleRemoveDocumentSection: PropTypes.func,
  setFiles: PropTypes.func,
  warning: PropTypes.bool,
}

export default Document
