import { Button, LoadingAnimation, Select } from '@keoworld/gbl-ui-kit'
import ModalUpload from 'containers/Modal/modal-documents-upload'
import LateralBar from 'containers/lateral-bar'
import { AlertContext } from 'providers/alert'
import { LATERAL_MENU_OPTIONS, LateralBarContext } from 'providers/lateral-bar'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiBuyerRepository } from 'services/buyer-suppcards/buyer.repository'
import { getDocumentList, uploadDocumentsInChunks } from 'services/documents'
import { getContract } from 'services/lines'
import styled from 'styled-components'
import { BuildSection } from 'templates/actions/document-actions/build-section'
import { useAuth } from 'utils/hooks/auth'
import Router from 'utils/router'
import {
  CUSTOMER_STATES,
  CUSTOMER_SUPPLIER_SOURCES,
  CUSTOMER_SUPPLIER_SOURCE_OPTIONS,
} from 'utils/schemas/customer'
import {
  CONTRACT_DOCUMENTS,
  CONTRACT_STATES,
  FILE_STAGES,
  FILE_STATES,
} from 'utils/schemas/documents'
import { FormValidationError } from 'utils/schemas/errors'
import { ONBOARDING_STATES } from 'utils/schemas/workflows/onboarding'

const ContractReview = () => {
  const { customerId } = useParams()
  const navigate = useNavigate()

  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)
  const [files, setFiles] = useState({})
  const [commentInfo, setCommentInfo] = useState(false)
  const [isValidating, setIsValidating] = useState(false)
  const [openModal, setOpenModal] = useState(false)
  const [messageModal, setMessageModal] = useState('')
  const [supplierSourceInput, setSupplierSourceInput] = useState(
    CUSTOMER_SUPPLIER_SOURCES.KEO
  )

  const { user } = useAuth()
  const { setAction, setSelectedOption } = useContext(LateralBarContext)
  const { setAlert } = useContext(AlertContext)

  const handleSupplierInputSwitch = ({ target }) =>
    setSupplierSourceInput(target?.value)

  const validateDocs = () => {
    const missingFiles = CONTRACT_DOCUMENTS.generalInformation.documents.some(
      ({ typeId, isMandatory = true }) => {
        if (!isMandatory) return false

        let documents = files[typeId] ?? []
        documents = documents.filter(
          (document) =>
            document.status !== FILE_STATES.DELETED &&
            document.status !== FILE_STATES.REFUSED
        )

        return documents.length === 0
      }
    )

    if (missingFiles) {
      setAlert({
        type: 'error',
        title: '¡Ups! Algo salió mal',
        label: `Por favor cargue los documentos para poder
          continuar con la activación del prospecto`,
      })
      return true
    }
  }

  const handleSubmit = async () => {
    setIsLoadingSubmit(true)
    setIsValidating(true)

    const { completeOnboarding, completeOnboardingWithCallao } =
      ONBOARDING_STATES.CONTRACT_SIGNED.transition

    try {
      validateDocs()

      const { contracts } = await getContract(customerId, CONTRACT_STATES.Draft)
      const [contractInfo] = contracts

      if (!contractInfo) {
        throw new FormValidationError(`
          No se encontró el contrato de este cliente,
          por favor comuníquese con soporte
        `)
      }

      const documents = await uploadDocumentsInChunks({
        files,
        customerId: customerId,
        user,
      })

      const multiUpdateIds = documents.map(({ id }) => ({
        id,
        status: FILE_STATES.ACCEPTED,
      }))

      let event = ''
      let eventData = {
        'fileList:multiUpdate': multiUpdateIds,
        'id:status': Number(customerId),
        'tradePlatform:status': supplierSourceInput,
      }

      if (supplierSourceInput === CUSTOMER_SUPPLIER_SOURCES.KEO) {
        event = completeOnboarding.event
      } else {
        event = completeOnboardingWithCallao.event
      }
      eventData = {
        ...eventData,
        'status:status': 'Ready',
        'wfState:status': completeOnboarding.nextState,
        'wfStage:status': completeOnboarding.nextStage,
        'contractId:contractUpdate': contractInfo.id,
        'status:contractUpdate': CONTRACT_STATES.Active,
      }

      await ApiBuyerRepository.doEvent(customerId, event, eventData)
      setIsLoadingSubmit(false)
      setAction(undefined)
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
      navigate(Router.Root)
    } catch (err) {
      console.log('Error activating customer:')
      console.log(err)
      if (err instanceof FormValidationError) {
        setAlert({
          type: 'error',
          title: '¡Ups! Algo salió mal',
          label: err.message,
        })
        return
      }

      setAlert({
        title: 'Error',
        label:
          'Ocurrió un error al activar el prospecto. Por favor, ingrese al dashboard de clientes y verifique el alta del mismo. De no ver el cambio, comuníquese con el Area de Soporte',
        type: 'error',
      })
      setIsLoadingSubmit(false)
      setAction(undefined)
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
      navigate(Router.Root)
    }
  }

  /* Fetch files effect */
  useEffect(() => {
    const fetchFiles = async () => {
      if (!customerId) return

      const { data: filesData } = await getDocumentList(customerId, {
        stage: FILE_STAGES.INSTRUMENTATION,
      })
      let contractFiles = {}
      filesData.forEach((itm) => {
        const extension = itm.fileExtension ? `.${itm.fileExtension}` : ''
        if (!contractFiles[itm.typeId]) contractFiles[itm.typeId] = []
        contractFiles[itm.typeId].push({
          name: `${itm.fileName}${extension}`,
          size: itm.fileSize,
          id: itm.fileId,
          typeId: itm.typeId,
        })
      })
      setFiles(contractFiles)
    }
    fetchFiles()
  }, [customerId])

  return (
    <ContractContainerStyled>
      <div>
        <h5>Documentos de contrato</h5>
        <BuildSection
          allowUpload
          allowDelete
          allowUpdate
          schema={CONTRACT_DOCUMENTS.generalInformation.documents}
          files={files}
          setFiles={setFiles}
          isValidating={isValidating}
          setCommentBarInfo={setCommentInfo}
        />
      </div>
      <div className='container'>
        <h4>Seleccionar flujo de customer:</h4>
        <label className='source-selector'>
          <Select
            name='supplierSource'
            label='Flujo de customer'
            value={supplierSourceInput}
            onChange={(e) => handleSupplierInputSwitch(e)}
          >
            {CUSTOMER_SUPPLIER_SOURCE_OPTIONS.map((itm, idx) => (
              <option value={itm.value} key={`opt-src-${idx}`}>
                {itm.label}
              </option>
            ))}
          </Select>
        </label>
      </div>
      <div className='action-group'>
        <Button
          buttonType='grayButton'
          onClick={() => {
            navigate(-1)
            setAction(false)
          }}
        >
          Cancelar
        </Button>
        <Button
          buttonType='green'
          className='send'
          disabled={isLoadingSubmit}
          onClick={() => {
            const error = validateDocs()
            if (error) return
            setOpenModal(true)
            setMessageModal(
              `Todos los documentos fueron aceptados, el prospecto
                será enviado a ${CUSTOMER_STATES.line_approved.label}`
            )
          }}
        >
          {!isLoadingSubmit && 'Enviar'}
          {isLoadingSubmit && <LoadingAnimation className='loading' />}
        </Button>
      </div>
      {commentInfo && (
        <LateralBar
          setLateralBar={setCommentInfo}
          fileId={commentInfo.fileId}
          documentName={commentInfo.fileName}
        />
      )}
      {openModal && (
        <ModalUpload
          setOpenModalUpload={setOpenModal}
          messages={{
            msg: messageModal,
            msgButton: 'Confirmar',
          }}
          handleSave={handleSubmit}
          isLoadingSubmit={isLoadingSubmit}
        />
      )}
    </ContractContainerStyled>
  )
}

const ContractContainerStyled = styled.div`
  padding: 2rem;
  padding-bottom: 1rem;
  .loading span {
    background-color: white;
  }
  h3 {
    margin-top: 1rem;
  }
  h4 {
    font-weight: bold;
    margin-top: 1rem;
  }
  .container {
    margin: 0 0.5rem;
  }
  .proposal {
    display: flex;
    justify-content: space-between;
    p {
      font-weight: bold;
    }
    .input-section {
      display: flex;
      justify-content: center;
      align-items: center;
      .grey {
        ${({ theme }) => theme.colors.grayShades[300]};
        border-radius: 6px;
        height: 39px;
        margin-right: 1rem;
        text-align: center;
        width: 50px;
        p {
          margin: 0.5rem auto;
        }
      }
      .input {
        max-width: 200px;
      }
    }
  }
  .action-group {
    margin: 2rem 0.5rem 0;

    button + button {
      margin-left: 28px;
    }
    .send {
      float: right;
    }
  }
  .file-row {
    display: flex;
    align-items: center;

    .button-section {
      display: flex;
      margin-left: 5%;
      button {
        width: 50px;
        border-radius: 6px;
        &.checked {
          ${({ theme }) => theme.buttons.outline.hover}
        }
      }
      button + button {
        margin-left: 15px;
      }
    }
  }
  .source-selector {
    display: flex;
  }
`

export default ContractReview
