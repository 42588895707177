import axios from 'axios'
import { WORKFLOW_ALIAS, WORKFLOW_PROJECT_ID } from 'utils/constants'
import { ENDPOINTS } from './config'
import { getCustomerInfo } from './customer'
import { getShareholderInfo } from './shareholder'
import { ApiError } from 'utils/schemas/errors'

const headers = () => ({
  'x-auth-workflow': localStorage.getItem('oauthIdToken'),
})

/**
 *
 * @param {string} customerId
 * @returns
 */
export const getCustomerInstance = async (customerId) => {
  const { data } = await axios.get(ENDPOINTS.workflow.instances, {
    params: {
      projectId: WORKFLOW_PROJECT_ID,
      instanceLocation: 'customers',
      principalId: customerId,
    },
  })
  return data
}

export const getOnboardingInstance = async (customerId) => {
  try {
    const { data } = await axios.get(ENDPOINTS.workflow.instances, {
      params: {
        projectId: WORKFLOW_PROJECT_ID,
        instanceLocation: 'buyer-onboarding',
        principalId: customerId,
      },
    })
    return data
  } catch (error) {
    console.error('INSTANCE NOT FOUND', error)
    return { body: {} }
  }
}

/**
 * Function to fetch instance information
 * @param {String} instanceId Instance ID
 * @param {String} instanceLocation Instance Location
 * @returns {Promise<{ body: Object }}
 */
export const getInstance = async (instanceId, instanceLocation) => {
  try {
    const { data } = await axios.get(ENDPOINTS.workflow.instances, {
      params: {
        projectId: WORKFLOW_PROJECT_ID,
        instanceLocation,
        principalId: instanceId,
      },
    })

    return data
  } catch (error) {
    if (axios.isAxiosError(error)) {
      throw new ApiError(
        'Workflow Error',
        'Error fetching instance information',
        error.response?.status
      )
    }

    throw error
  }
}

/**
 *
 * @param {string} lineId
 * @param {string} customerId
 * @returns
 */
export const getLineInstance = async (lineId, customerId) => {
  const { data } = await axios.get(ENDPOINTS.workflow.instances, {
    params: {
      projectId: WORKFLOW_PROJECT_ID,
      instanceLocation: 'customers.lines',
      principalId: `${customerId}.${lineId}`,
    },
  })
  return data
}

/**
 *
 * @param {string} [alias] - customer | line
 * @returns
 */
export const getProjectWorkflows = async (alias = 'customer') => {
  const { data } = await axios.get(ENDPOINTS.workflow.versions, {
    headers: headers(),
    params: {
      alias: WORKFLOW_ALIAS[alias],
    },
  })
  return data
}

/**
 * @description Function to fetch buyer information
 * @param {Number} customerId
 * @param {String} service
 * @returns
 */
export const fetchCustomerInstances = async (customerId, service) => {
  let customerInstance = {}

  if (service !== 'supplementaryCards') {
    const { body } = await getCustomerInstance(customerId)
    customerInstance = body.instance
  }

  const { data: customer } = await getCustomerInfo(customerId)
  const { data: shareholder } = await getShareholderInfo(
    customer?.shareholderId
  )

  return {
    customerInstance,
    type: shareholder.type,
    ...customer,
  }
}

export const getStateTimeConfig = async (workflowId) => {
  const { data } = await axios.get(ENDPOINTS.workflow.timeConfig, {
    headers: headers(),
    params: { workflowId },
  })
  return data
}

/**
 *
 * @param {string} workflowId
 * @param {object} form
 * @param {string} form.address:location
 * @param {string} form.city:location
 * @param {string} form.state:location
 * @param {string} form.municipality:location
 * @param {string} form.colony:location
 * @param {string} form.externalNumber:location
 * @param {string} form.internalNumber:location
 * @param {string} form.zipCode:location - not in DB
 * //
 * @param {string} form.name:agent
 * @param {string} form.firstSurname:agent
 * @param {string} form.secondSurname:agent
 * @param {string} form.areaCode:agent
 * @param {string} form.phone:agent
 * //
 * @param {string} form.name:user
 * @param {string} form.email:user
 * @param {string} form.phone:user
 * @param {string} form.firstSurname:user
 * @param {string} form.secondSurname:user
 * @param {string} form.areaCode:user
 * @param {string} form.position:user - not in form
 * @param {string} form.authorizedBy:user - not in form
 * //
 * @param {string} form.name:customer
 * @param {string} form.tin:customer
 * @param {string} form.mainActivity:customer
 * @param {string} form.sat_industries:customer - not in DB (select)
 * @param {string} form.zipCode:customer (QUIT FROM DB)
 * @param {string} form.status:customer - not in form
 * @param {number} shareholderId
 * @returns
 */
export const createCustomer = (workflowId, form, shareholderId) => {
  const body = buildCreateInstanceBody({
    workflowId,
    requestBody: { ...form, shareholderId },
  })
  return axios.post(ENDPOINTS.workflow.instances, body, { headers: headers() })
}

/**
 *
 * @param {string} workflowId
 * @param {string} principalId
 * @returns
 */
export const createLineInstance = (workflowId, principalId) => {
  const body = buildCreateInstanceBody({
    workflowId,
    principalId,
    requestBody: {},
  })
  return axios.post(ENDPOINTS.workflow.instances, body, { headers: headers() })
}

/**
 *
 * @param {string} workflowId
 * @param {string} principalId
 * @param {string} event
 * @param {object} form
 * @returns
 */
export const instanceTransition = (
  workflowId,
  principalId,
  event,
  form = {}
) => {
  const body = buildTransitionBody({
    workflowId,
    principalId,
    event,
    requestBody: form,
  })
  return axios.post(ENDPOINTS.workflow.transition, body, { headers: headers() })
}

/**
 *
 * @param {string} event
 * @param {object} requestBody
 * @returns
 */
const buildTransitionBody = ({
  workflowId,
  principalId,
  event,
  requestBody,
}) => ({
  workflowId,
  principalId,
  event,
  context: requestBody,
})

const buildCreateInstanceBody = ({ workflowId, principalId, requestBody }) => ({
  workflowId,
  principalId,
  context: requestBody,
})
