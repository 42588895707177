import Document from 'components/document'
import PropTypes from 'prop-types'
import { AlertContext } from 'providers/alert'
import { useContext } from 'react'
import { downloadDocument } from 'services/documents'
import styled from 'styled-components'
import { FILE_MAX_SIZE } from 'utils/constants'
import { bytesConverter } from 'utils/functions/bytes-converter'
import { downloadFile } from 'utils/functions/download-file'
import { FILE_STATES } from 'utils/schemas/documents'

export const BuildSection = (props) => {
  const { setAlert } = useContext(AlertContext)

  return (
    <DocumentStyled className={props.className}>
      {props.title && <h5>{props.title}</h5>}
      {props.schema.map((documentInformation, index) => {
        const {
          label,
          documentType,
          typeId,
          isMandatory = true,
          allowRemoveDocumentSection = false,
        } = documentInformation
        const files = props.files[typeId] || []
        const containsUndeletedFiles = !files.some(
          (file) => file.status !== FILE_STATES.DELETED
        )
        const setter = (newFiles) => {
          if (typeof props.setFiles !== 'function') return
          if (newFiles.some((file) => file.size > FILE_MAX_SIZE)) {
            const megabytes = bytesConverter(FILE_MAX_SIZE)
            setAlert({
              title: 'Error',
              label: `Los archivos no pueden pesar más de ${megabytes} MB.`,
              type: 'error',
            })
            return
          }
          props.setFiles({ ...props.files, [typeId]: newFiles })
        }

        const onComment = (file) => {
          if (typeof props.setCommentBarInfo !== 'function') return
          props.setCommentBarInfo({
            fileId: file.fileId || file.id,
            fileName: file.name,
            typeId,
          })
        }

        const onDownload = async (file) => {
          if (!file.id) return
          const response = await downloadDocument(file.fileId || file.id)
          downloadFile(response, file.name)
        }

        const showWarning = () => {
          return isMandatory
            ? props.isValidating &&
                (files.length === 0 || containsUndeletedFiles)
            : false
        }

        return (
          <Document
            allowDelete={props.allowDelete}
            allowReview={props.allowReview}
            allowUpload={props.allowUpload}
            allowDownload={props.allowDownload}
            allowRemoveDocumentSection={allowRemoveDocumentSection}
            allowedDocumentTypes={documentType}
            allowUpdate={props.allowUpdate}
            className='document'
            files={files}
            key={`document-${index}`}
            label={label}
            onClickComment={props.setCommentBarInfo && onComment}
            onClickDownload={onDownload}
            setFiles={setter}
            warning={showWarning()}
            onHandleRemoveDocumentSection={() =>
              props.onHandleRemoveDocumentSection(documentInformation)
            }
          />
        )
      })}
    </DocumentStyled>
  )
}

const DocumentStyled = styled.section`
  .document + .document {
    margin-top: 1rem;
  }
`

BuildSection.propTypes = {
  allowComment: PropTypes.bool,
  allowDelete: PropTypes.bool,
  allowReview: PropTypes.bool,
  allowUpdate: PropTypes.bool,
  allowUpload: PropTypes.bool,
  allowDownload: PropTypes.bool,
  onHandleRemoveDocumentSection: PropTypes.func,
  schema: PropTypes.array.isRequired,
  files: PropTypes.object.isRequired,
  isValidating: PropTypes.bool,
  setCommentBarInfo: PropTypes.func,
  setFiles: PropTypes.func,
  title: PropTypes.string,
  className: PropTypes.string,
}
