import { Icon, Input } from '@keoworld/gbl-ui-kit'
import { LoadingButton } from '@mui/lab'
import { ButtonGroup } from '@mui/material'
import axios from 'axios'
import ModalUpload from 'containers/Modal/modal-documents-upload'
import LateralBar from 'containers/lateral-bar'
import DocumentsRequested from 'containers/legal-risk/documents-requested'
import { AlertContext } from 'providers/alert'
import { CustomerContext } from 'providers/customer'
import { LATERAL_MENU_OPTIONS, LateralBarContext } from 'providers/lateral-bar'
import { useContext, useEffect, useState } from 'react'
import { useNavigate, useParams } from 'react-router-dom'
import { ApiBuyerRepository } from 'services/buyer-suppcards/buyer.repository'
import { fileRequestFind, uploadDocuments } from 'services/documents'
import styled from 'styled-components'
import { BuildSection } from 'templates/actions/document-actions/build-section'
import { getUserRoles } from 'utils/functions/role-manager'
import { useAuth } from 'utils/hooks/auth'
import { useFetchDocuments } from 'utils/hooks/fetch-documents'
import { FILE_STAGES, FILE_STATES, LEGAL_RISK } from 'utils/schemas/documents'
import { ApiError, FormValidationError } from 'utils/schemas/errors'
import { ONBOARDING_STATES } from 'utils/schemas/workflows/onboarding'

const FILES_FILTER = { stage: FILE_STAGES.RISK }

const PropertyReview = () => {
  const [userRole] = getUserRoles()
  const [files, setFiles] = useState({})
  const [documentRequired, setDocumentRequired] = useState('')
  const [documentsRequired, setDocumentsRequired] = useState([])
  const [documentsRequested, setDocumentsRequested] = useState({})
  const [documentsRequestedSchema, setDocumentsRequestedSchema] = useState([])
  const [modalConfig, setModalConfig] = useState(undefined)
  const [openCommentBar, setOpenCommentBar] = useState(false)
  const [isValidating, setIsValidating] = useState(false)
  const [isLoadingSubmit, setIsLoadingSubmit] = useState(false)

  const { customer } = useContext(CustomerContext)
  const { setAction, setSelectedOption } = useContext(LateralBarContext)
  const { setAlert } = useContext(AlertContext)

  const { customerId } = useParams()
  const navigate = useNavigate()

  const { user } = useAuth()
  const documents = useFetchDocuments(customer?.id, FILES_FILTER)

  const onboardingEvent = ONBOARDING_STATES.PROPERTY_REVIEW.transition

  const addDocument = (event) => {
    event.preventDefault()
    if (documentRequired) {
      setDocumentsRequired([...documentsRequired, documentRequired])
      setDocumentRequired('')
    }
  }

  const requestCorrections = async () => {
    const { event, nextState } =
      onboardingEvent.requestCorrectedPropertyDocuments

    const docsSaved = await saveDocuments(files)
    const fileList = docsSaved.map(({ id, status }) => ({ id, status }))

    const additionalFiles = Object.values(documentsRequested).reduce(
      (acc, files) => {
        const extraDocuments = files.map(({ id, status }) => ({ id, status }))
        return [...acc, ...extraDocuments]
      },
      []
    )

    const eventData = {
      'fileList:multiUpdate': [...fileList, ...additionalFiles],
      'id:status': customer.id,
      'wfState:status': nextState,
      'uuid:assignation': user.uid,
      'customerId:assignation': customer.id,
      'id:assignation': customer.assignationId,
      'role:assignation': userRole,
    }

    await ApiBuyerRepository.doEvent(customer.id, event, eventData)

    navigate('/')
    setAction(undefined)
    setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
  }

  const requestDocuments = async () => {
    setIsLoadingSubmit(true)
    try {
      if (documentsRequired.length > 0) {
        const { event, nextState, fileTypeId } =
          onboardingEvent.requestAdditionalDocuments

        const eventData = {
          'names:fileRequest': documentsRequired,
          'customerId:fileRequest': Number(customerId),
          'fileTypeId:fileRequest': fileTypeId,
          'id:status': Number(customerId),
          'wfState:status': nextState,
          'uuid:assignation': user.uid,
          'customerId:assignation': customer.id,
          'id:assignation': customer.assignationId,
          'role:assignation': userRole,
        }

        await ApiBuyerRepository.doEvent(customerId, event, eventData)

        navigate('/')
        setAction(undefined)
        setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
      }
    } catch (error) {
      console.error(error)
    }

    setIsLoadingSubmit(false)
  }

  const validateFiles = () => {
    const isEmpty = Object.values(files).every(
      (files) =>
        files.length === 0 ||
        files.every((file) => file.status === FILE_STATES.DELETED)
    )

    return isEmpty
  }

  const saveDocuments = async (files, status) => {
    const filesToUpload = LEGAL_RISK.reduce((acc, document) => {
      const documents = files[document.typeId] || []
      const filesByTypeId = documents.map((file) => {
        if (status && file.status !== FILE_STATES.DELETED) {
          file.status = status
        }
        return file
      })
      return {
        ...acc,
        [document.typeId]: filesByTypeId,
      }
    }, {})

    const response = await uploadDocuments(
      filesToUpload,
      customerId,
      user.uid,
      userRole
    )
    if (response.some((request) => request.status !== 'fulfilled')) {
      throw new Error('error saving files')
    }
    return response.map((request) => request.value)
  }

  const onSave = async () => {
    try {
      setIsLoadingSubmit(true)
      await saveDocuments(files)
      setAction(false)
      navigate(-1)
    } catch (error) {
      console.error(error.message)
      const alert = {
        label:
          'Ha ocurrido un error al guardar los archivos, por favor intenta de nuevo',
        title: 'Error',
        type: 'error',
      }
      if (error.message === 'error saving files') {
        setAction(false)
        setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
      }
      setAlert(alert)
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  const handleSubmitReview = () => {
    setIsValidating(true)
    const isAllAccepted = documentsRequestedSchema.every((document) => {
      const files = documentsRequested[document.typeId]
      return files
        .filter((file) => file.status !== FILE_STATES.DELETED)
        .every((file) => file.status === FILE_STATES.ACCEPTED)
    })

    if (validateFiles()) {
      setAlert({
        label: 'Por favor sube todos los documentos solicitados',
        title: 'Error',
        type: 'error',
      })
      return
    }

    if (isAllAccepted) {
      handleApproveReview()
    } else {
      setModalConfig({
        message:
          'Algunos documentos NO fueron aceptados, el prospecto será enviado a información adicional requerida.',
        actionLabel: 'Aceptar',
        action: requestCorrections,
      })
    }
  }

  const handleApproveReview = async () => {
    try {
      setIsLoadingSubmit(true)

      const docsSaved = await saveDocuments(files, FILE_STATES.ACCEPTED)
      const fileList = docsSaved.map(({ id, status }) => ({ id, status }))

      const { event, nextState } = onboardingEvent.approvePropertyDocuments

      const additionalFiles = Object.values(documentsRequested).reduce(
        (acc, files) => {
          const extraDocuments = files.map(({ id, status }) => ({ id, status }))
          return [...acc, ...extraDocuments]
        },
        []
      )

      const eventData = {
        'fileList:multiUpdate': [...fileList, ...additionalFiles],
        'id:status': Number(customerId),
        'wfState:status': nextState,
        'uuid:assignation': user.uid,
        'customerId:assignation': customer.id,
        'id:assignation': customer.assignationId,
        'role:assignation': userRole,
      }

      await ApiBuyerRepository.doEvent(customerId, event, eventData)

      navigate('/')
      setAction(undefined)
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
    } catch (error) {
      const messages = {
        'missing files': {
          close: false,
          label: 'Por favor sube todos los documentos solicitados',
        },
        'error saving files': {
          close: true,
          label:
            'Ha ocurrido un error al guardar los archivos, por favor ingresa al prospecto e intenta subir los documentos de nuevo',
        },
        workflow: {
          close: true,
          label:
            'Los documentos se han guardado, pero ha ocurrido un error al enviar. Por favor contacta a soporte',
        },
        default: {
          close: true,
          label: 'Ha ocurrido un error al enviar. Por favor recarga la pagina',
        },
      }

      if (axios.isAxiosError(error)) {
        const { body } = error.response.data
        console.error('workflow error:', body?.message)
        error.message = 'workflow'
      }

      const { close, label } = messages[error.message] || messages.default

      if (close) {
        setAction(false)
        setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
      }

      setAlert({
        label,
        title: 'Error',
        type: 'error',
      })
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  const rejectProspect = async () => {
    try {
      setIsLoadingSubmit(true)

      if (validateFiles()) {
        throw new FormValidationError(
          'Por favor sube todos los documentos solicitados'
        )
      }

      const docsSaved = await saveDocuments(files, FILE_STATES.ACCEPTED)
      const fileList = docsSaved.map(({ id, status }) => ({ id, status }))

      const additionalFiles = Object.values(documentsRequested).reduce(
        (acc, files) => {
          const extraDocuments = files.map(({ id, status }) => ({ id, status }))
          return [...acc, ...extraDocuments]
        },
        []
      )

      const { event, nextState, nextStage } =
        onboardingEvent.rejectPropertyDocuments

      const eventData = {
        'fileList:multiUpdate': [...fileList, ...additionalFiles],
        'id:status': Number(customerId),
        'wfState:status': nextState,
        'wfStage:status': nextStage,
        'uuid:assignation': user.uid,
        'customerId:assignation': customer.id,
        'id:assignation': customer.assignationId,
        'role:assignation': userRole,
      }

      await ApiBuyerRepository.doEvent(customerId, event, eventData)

      navigate('/')
      setAction(undefined)
      setSelectedOption(LATERAL_MENU_OPTIONS.DASHBOARD_ONBOARDING)
    } catch (error) {
      if (!axios.isAxiosError(error)) {
        setAlert({
          label: error.message,
          title: 'Error',
          type: 'error',
        })
        return
      }

      setAlert({
        title: '¡Ha ocurrido un error!',
        type: error.response.status === 400 ? 'warning' : 'error',
        label: error.response.data?.message,
      })
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  const onRemoveDocumentSection = async (section) => {
    try {
      setIsLoadingSubmit(true)
      const { fileRequestId } = section

      const { event } = onboardingEvent.removeDocumentSection

      await ApiBuyerRepository.doEvent(customerId, event, {
        pathParams: { id: fileRequestId },
      })

      setDocumentsRequestedSchema(
        documentsRequestedSchema.filter(
          (document) => document.fileRequestId !== fileRequestId
        )
      )

      setModalConfig(undefined)
    } catch (error) {
      if (error instanceof ApiError) {
        return setAlert({
          type: error.statusCode === 400 ? 'warning' : 'error',
          title: error.title,
          label: error.message,
        })
      }

      setAlert({
        type: 'error',
        title: '¡Ha ocurrido un error!',
        label: error.message,
      })
    } finally {
      setIsLoadingSubmit(false)
    }
  }

  useEffect(() => {
    const fetchFiles = async () => {
      const savedFiles = documents.reduce((acc, itm) => {
        const docs = acc[itm.typeId] || []
        const extension = itm.fileExtension ? `.${itm.fileExtension}` : ''
        docs.push({
          name: `${itm.fileName}${extension}`,
          size: itm.fileSize,
          status: itm.fileStatus,
          id: itm.fileId,
          fileExtension: itm.fileExtension,
          typeId: itm.typeId,
        })
        return { ...acc, [itm.typeId]: docs }
      }, {})

      const legalFiles = LEGAL_RISK.reduce((acc, document) => {
        const { typeId } = document
        const legalRiskFiles = savedFiles[typeId] ?? []

        return { ...acc, [typeId]: legalRiskFiles }
      }, {})

      setFiles(legalFiles)
    }
    fetchFiles()
  }, [documents])

  useEffect(() => {
    const getFileRequest = async () => {
      if (!customerId) return
      try {
        let schema = []
        const files = {}
        const { data } = await fileRequestFind(customerId)

        data.forEach((request) => {
          const { data, fileRequestId, fileRequestName, fileTypeId } = request
          schema = [
            ...schema,
            {
              label: fileRequestName,
              typeId: fileRequestId,
              documentType: ['.pdf', 'image/*'],
              fileRequestId,
              allowRemoveDocumentSection: true,
            },
          ]

          files[fileRequestId] = data.map((file) => {
            const { fileId, fileName, fileSize, fileStatus, fileExtension } =
              file
            const extension = fileExtension ? `.${fileExtension}` : ''
            return {
              label: fileName,
              name: `${fileName}${extension}`,
              size: fileSize,
              status:
                fileStatus === FILE_STATES.PENDING
                  ? FILE_STATES.REFUSED
                  : fileStatus,
              fileRequestId,
              fileId,
              id: fileId,
              fileExtension,
              fileTypeId,
            }
          })
        })

        setDocumentsRequestedSchema(schema)
        setDocumentsRequested(files)
      } catch (err) {
        console.error(err)
      }
    }

    getFileRequest()
  }, [customerId])

  return (
    <LegalRiskStyled>
      <section className='legal-documents'>
        <CustomerDocumentsStyled>
          <BuildSection
            allowComment
            allowDelete
            allowUpdate
            files={files}
            setFiles={setFiles}
            isValidating={isValidating}
            schema={LEGAL_RISK}
            setCommentBarInfo={setOpenCommentBar}
            title='Documentos legales'
          />
        </CustomerDocumentsStyled>
      </section>
      <section className='additional-documents'>
        <h3>Documentos adicionales</h3>

        <div>
          Selecciona los documentos adicionales que requieres y dale clic a
          “Solicitar documentos”, los nuevos documentos aparecerán en esta misma
          sección.
        </div>
        <form onSubmit={addDocument}>
          <Input
            name='documentRequired'
            placeholder='Agregar documentos'
            icon={<Icon name='add' onClick={addDocument} />}
            value={documentRequired}
            onChange={(event) => setDocumentRequired(event.target.value)}
          />
        </form>

        <div className='documents-required'>
          {documentsRequired.map((document, index) => {
            return (
              <span className='chip' key={`chip-${index}`}>
                {document}
                <Icon
                  name='close'
                  onClick={() =>
                    setDocumentsRequired(
                      documentsRequired.filter((_, i) => index !== i)
                    )
                  }
                />
              </span>
            )
          })}
        </div>
      </section>

      {documentsRequestedSchema.length > 0 && (
        <DocumentsRequested
          documentsRequested={documentsRequested}
          setDocumentsRequested={setDocumentsRequested}
          documentsRequestedSchema={documentsRequestedSchema}
          setOpenCommentBar={setOpenCommentBar}
          warning={isValidating}
          onHandleRemoveDocumentSection={(fileRequest) =>
            setModalConfig({
              message: `¿Estás seguro de eliminar la solicitud de estos documentos?
              esta acción no podrá ser revertida.`,
              actionLabel: 'Aceptar',
              action: () => onRemoveDocumentSection(fileRequest),
            })
          }
        />
      )}

      <section className='view-actions'>
        <ButtonGroup variant='text'>
          <LoadingButton
            className='btn-text-red'
            loading={isLoadingSubmit}
            onClick={() => {
              setAction(undefined)
              navigate(-1)
            }}
          >
            <span>Cancelar</span>
          </LoadingButton>
          <LoadingButton
            className='btn-save-files'
            loading={isLoadingSubmit}
            onClick={() => {
              setModalConfig({
                message: `¿Estás seguro de guardar los documentos cargados?
                  Esta acción no cambiará el estado del cliente.`,
                actionLabel: 'Aceptar',
                action: onSave,
              })
            }}
          >
            <span>Guardar</span>
          </LoadingButton>
        </ButtonGroup>

        <ButtonGroup variant='outlined'>
          <LoadingButton
            className='btn-outlined-red'
            loading={isLoadingSubmit}
            onClick={() =>
              setModalConfig({
                message: `¿Estás seguro de rechazar el prospecto?
                  Después de rechazado el prospecto podrá retomar el proceso.`,
                actionLabel: 'Aceptar',
                action: rejectProspect,
              })
            }
          >
            <span>Rechazar</span>
          </LoadingButton>
          <LoadingButton
            className='btn-outlined-blue-dark'
            loading={isLoadingSubmit}
            onClick={() =>
              setModalConfig({
                message: `¿Estás seguro de enviar el prospecto
                  a información adicional requerida?`,
                actionLabel: 'Aceptar',
                action: requestDocuments,
              })
            }
          >
            <span>Solicitar documentos</span>
          </LoadingButton>
          <LoadingButton
            className='btn-blue-dark'
            variant='contained'
            loading={isLoadingSubmit}
            endIcon={<Icon name='arrow_forward' />}
            onClick={() =>
              setModalConfig({
                message: `¿Está seguro de aprobar el prospecto?
                  Está acción no podrá ser revertida.`,
                actionLabel: 'Aceptar',
                action: handleSubmitReview,
              })
            }
          >
            <span>Continuar</span>
          </LoadingButton>
        </ButtonGroup>
      </section>

      {openCommentBar && (
        <LateralBar
          fileId={openCommentBar.fileId}
          documentName={openCommentBar.fileName}
          setLateralBar={setOpenCommentBar}
        />
      )}

      {modalConfig && (
        <ModalUpload
          setOpenModalUpload={() => setModalConfig(undefined)}
          isLoadingSubmit={isLoadingSubmit}
          messages={{
            msg: modalConfig.message,
            msgButton: modalConfig.actionLabel,
          }}
          handleSave={modalConfig.action}
        />
      )}
    </LegalRiskStyled>
  )
}

const CustomerDocumentsStyled = styled.section`
  width: 60%;

  h5 {
    margin-top: 1rem;
  }

  section {
    width: 65%;
  }
`

const LegalRiskStyled = styled.section`
  padding: 2rem;

  .loading span {
    background-color: white;
  }

  .additional-documents {
    margin-top: 1rem;
    width: 60%;
    min-width: 600px;
    .input {
      margin: 20px 0;
      .icon {
        cursor: pointer;
      }
    }
    .documents-required {
      margin: 20px 0;
      display: flex;
      flex-wrap: wrap;
      gap: 5px;

      .chip {
        display: flex;
        align-items: center;
        justify-content: space-between;

        ${({ theme }) => theme.colors.grayShades[600]}
        border-radius: 20px;
        padding: 3px 15px;

        .icon {
          cursor: pointer;
          margin-left: 20px;
          margin-right: -5px;
          font-size: 15px;
        }
      }
    }
  }

  .view-actions {
    display: flex;
    justify-content: space-between;

    :not(:has(.MuiLoadingButton-loading)) {
      .btn-text-red {
        color: #ff0045;
      }

      .btn-outlined-red {
        border-color: #ff0045;
        color: #ff0045;
      }

      .btn-outlined-blue-dark {
        border-color: #00172d;
        color: #00172d;
      }

      .btn-blue-dark {
        background-color: #00172d;
      }
    }
  }
`

export default PropertyReview
