import { FormControl, InputLabel, MenuItem, Select } from '@mui/material'
import DebtCapacitiesHistory from 'modules/debt-capacity/views/debt-capacities-history'
import CustomerManager from './history-manager'
import { useState } from 'react'
import styled from 'styled-components'

const HISTORY_SELECTOR = {
  ONBOARDING: {
    key: 'ONBOARDING',
    label: 'Onboarding',
    component: CustomerManager,
  },
  DEBT_CAPACITIES: {
    key: 'DEBT_CAPACITIES',
    label: 'Capacidades de endeudamiento',
    component: DebtCapacitiesHistory,
  },
}

const HistoryController = () => {
  const [historySelector, setHistorySelector] = useState(
    HISTORY_SELECTOR.ONBOARDING.key
  )

  const ComponentSelected = HISTORY_SELECTOR[historySelector].component

  return (
    <Styles>
      <FormControl variant='standard' sx={{ m: 1, minWidth: 80 }}>
        <InputLabel id='label-selector-history'>Historial</InputLabel>
        <Select
          labelId='label-selector-history'
          id='selector-history'
          value={historySelector}
          onChange={(e) => setHistorySelector(e.target.value)}
          label='Historial'
        >
          {Object.values(HISTORY_SELECTOR).map((option) => (
            <MenuItem key={option.key} value={option.key}>
              {option.label}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
      <ComponentSelected />
    </Styles>
  )
}

const Styles = styled.main`
  padding-inline: 3rem;
  padding-block: 2rem;
`

export default HistoryController
