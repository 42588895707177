/* eslint-disable react-hooks/exhaustive-deps */
import { Icon, Input, Select, Switch } from '@keoworld/gbl-ui-kit'
import { LoadingButton } from '@mui/lab'
import {
  Accordion,
  AccordionDetails,
  AccordionSummary,
  Button,
  ButtonGroup,
  List,
  ListItem,
  ListSubheader,
  Skeleton,
  Grid2 as Grid,
} from '@mui/material'
import Form from 'containers/form'
import { AlertContext } from 'providers/alert'
import { useCallback, useContext, useEffect, useState } from 'react'
import { collectionCreate } from 'services/lines'
import { LoanRepository } from 'services/loan.repository'
import styled from 'styled-components'
import { dateFormatFromIso, getUTCDateTime } from 'utils/functions/date'
import { currency } from 'utils/functions/formatters'
import { gaussianRounding } from 'utils/functions/gaussian-rounding'
import * as FormatCurrency from 'utils/functions/handlers-currency'
import { validateValue } from 'utils/functions/validators'
import { ApiError, FormValidationError } from 'utils/schemas/errors'
import { LOAN_STATUS } from 'utils/schemas/loan'
import { PAYMENT_CONCEPT } from 'utils/schemas/payments'

const INPUT_DATE_TIME_FORMAT = "yyyy-MM-dd'T'HH:mm:ss"

const verifyErrors = (data) => {
  const formErrors = Object.values(data)
  return formErrors.some((isThereError) => isThereError)
}

const hasElementsRepeated = (array, property) => {
  for (const element of array) {
    const repeated = array.filter(
      (item) => item[property] === element[property]
    )
    if (repeated.length > 1) {
      return true
    }
  }
  return false
}

const PaymentRegister = (props) => {
  const { loan, onHandleClose, showCondonationModal } = props
  const [{ MIN_DATE_PAY, MAX_DATE_PAY }] = useState({
    MIN_DATE_PAY: dateFormatFromIso(loan.createdAt, INPUT_DATE_TIME_FORMAT),
    MAX_DATE_PAY: dateFormatFromIso(new Date(), INPUT_DATE_TIME_FORMAT),
  })
  const [paymentSummary, setPaymentSummary] = useState({
    paymentType: '',
    reference: '',
    date: MAX_DATE_PAY,
    amount: '',
    priority: true,
  })
  const [requireFetchingLoanProjection, setRequireFetchingLoanProjection] =
    useState(true)
  const [validating, setValidating] = useState(false)
  const [isFetchingLoanProjection, setIsFetchingLoanProjection] =
    useState(false)
  const [submitLoading, setSubmitLoading] = useState(false)
  const [paymentConcepts, setPaymentConcepts] = useState([])
  const [errorsPaymentConcepts, setErrorsPaymentConcepts] = useState([])
  const [conceptsSchema, setConceptsSchema] = useState(PAYMENT_CONCEPT)
  const [concepts, setConcepts] = useState([])
  const [calculator, setCalculator] = useState({
    totalAmount: 0,
    concepts: 0,
    missingAmount: 0,
  })

  const [loanInformation, setLoanInformation] = useState({
    loading: true,
    concepts: [],
    repayments: [],
  })

  const { setAlert } = useContext(AlertContext)

  const validateForm = () => {
    if (requireFetchingLoanProjection) {
      throw new FormValidationError(
        'Require calculate the debt of the loan',
        'requireFetchLoanProjection'
      )
    }

    if (!paymentSummary.paymentType) {
      throw new FormValidationError('select a payment type', 'paymentType')
    }

    if (
      paymentSummary.date < MIN_DATE_PAY ||
      paymentSummary.date > MAX_DATE_PAY
    ) {
      throw new FormValidationError(
        'the date cannot be greater than the current date and less than the creation date of the loan',
        'date'
      )
    }

    if (!paymentSummary.amount) {
      throw new FormValidationError('enter the total amount', 'amount')
    }

    if (!paymentSummary.priority) {
      if (paymentConcepts.length === 0) {
        throw new FormValidationError('enter the concepts', 'missingConcepts')
      }
      if (errorsPaymentConcepts.some((concept) => verifyErrors(concept))) {
        throw new FormValidationError(
          'enter the concepts correctly',
          'concepts'
        )
      }

      if (hasElementsRepeated(paymentConcepts, 'concept')) {
        throw new FormValidationError(
          'there are repeated concepts',
          'repeatedConcepts'
        )
      }

      const validateConceptAmounts = paymentConcepts.some(
        ({ concept, amount }) => {
          const debt = concepts.find((debt) => debt.debtId === Number(concept))
          const formattedAmount = FormatCurrency.extractNumber(amount)
          return debt && debt.totalDebt < formattedAmount
        }
      )

      if (validateConceptAmounts) {
        throw new FormValidationError(
          'the amount of the concept cannot be greater than the debt',
          'conceptsAmount'
        )
      }

      const amount = FormatCurrency.extractNumber(paymentSummary.amount)
      const total = paymentConcepts
        .map((concept) => FormatCurrency.extractNumber(concept.amount))
        .reduce((acc, amount) => gaussianRounding(acc + amount), 0)

      if (total !== amount) {
        throw new FormValidationError(
          'the total amount is not equal to the sum of the concepts',
          'conceptAmount'
        )
      }
    }
  }

  const onHandleSubmit = async () => {
    try {
      setValidating(true)
      setSubmitLoading(true)

      validateForm()

      const payment = {
        amount: FormatCurrency.extractNumber(paymentSummary.amount),
        loanId: loan.id,
        madeAt: getUTCDateTime(new Date(paymentSummary.date)),
        priority: paymentSummary.priority,
        reference: paymentSummary.reference,
        source: 'Manual',
        type: paymentSummary.paymentType,
      }

      if (!paymentSummary.priority) {
        payment.concepts = paymentConcepts.map(({ concept, amount }) => ({
          debtId: Number(concept),
          amount: FormatCurrency.extractNumber(amount),
        }))
      }

      if (paymentSummary.paymentType === 'Condonation') {
        showCondonationModal(payment)
      } else {
        await collectionCreate(payment)

        setAlert({
          type: 'success',
          title: '¡Listo!',
          label: 'Tu pago fue procesado exitosamente.',
        })

        onHandleClose()
      }
    } catch (error) {
      const alertMessage = {
        title: 'Error',
        type: 'error',
        label: `Ocurrió un error al procesar el pago. Por favor verifique si el pago
          se registró correctamente, de lo contrario comuníquese con el area de soporte`,
      }
      if (error instanceof FormValidationError) {
        const errorMessages = {
          requireFetchLoanProjection: 'Calcule la deuda del préstamo',
          paymentType: 'Seleccione un tipo de pago',
          amount: 'Ingrese el monto total que desea pagar',
          missingConcepts: 'Ingrese los conceptos de pago',
          concepts: 'Complete los campos de los conceptos',
          conceptAmount:
            'La suma de los conceptos debe ser igual al monto total',
          date: `La fecha y hora de pago no puede ser menor a la fecha y hora
            de creación del préstamo, ni mayor a la fecha y hora actual`,
          repeatedConcepts:
            'Hay conceptos repetidos, por favor ingrese un solo monto por concepto',
          conceptsAmount: `El monto de uno de los conceptos no puede ser mayor
            a la deuda generada en ese mismo concepto`,
        }

        if (errorMessages[error.fields]) {
          alertMessage.label = errorMessages[error.fields]
        }
      } else {
        onHandleClose()
      }
      setAlert(alertMessage)
    } finally {
      setSubmitLoading(false)
    }
  }

  const addPaymentConcept = () => {
    setPaymentConcepts([...paymentConcepts, { amount: '', concept: '' }])
    setErrorsPaymentConcepts([
      ...errorsPaymentConcepts,
      { amount: true, concept: true },
    ])
  }

  const onRemovePaymentConcept = (index) => {
    setPaymentConcepts((prev) => {
      const array = [...prev]
      array.splice(index, 1)
      return array
    })
    setErrorsPaymentConcepts((prev) => {
      const array = [...prev]
      array.splice(index, 1)
      return array
    })
  }

  const onChangePaymentSummary = (event) => {
    if (!event) return

    const { name, value } = event.target

    setPaymentSummary((prev) => ({
      ...prev,
      [name]: value,
    }))
  }

  const fetchLoanProjection = useCallback(
    async (event) => {
      try {
        if (event) event.preventDefault()
        if (!loan?.id) return
        if (!paymentSummary.date)
          throw new FormValidationError('The payment date is require', 'date')

        setIsFetchingLoanProjection(true)

        const { total, concepts } = await LoanRepository.fetchLoanProjection({
          loanId: loan.id,
          date: getUTCDateTime(new Date(paymentSummary.date)),
        })

        const paymentConceptSchema = [...PAYMENT_CONCEPT]
        const indexOfConcept = paymentConceptSchema.findIndex(
          (field) => field.name === 'concept'
        )
        paymentConceptSchema[indexOfConcept].options = concepts
          .filter((concept) => concept.totalDebt > 0)
          .map((concept) => {
            const { debtId, totalDebt, name } = concept
            const formattedTotalDebt = FormatCurrency.formatCurrency(totalDebt)

            return {
              value: debtId,
              label: `${name} (${formattedTotalDebt})`,
            }
          })

        const missingAmount = concepts.reduce(
          (prev, curr) => prev + curr.totalDebt,
          0
        )

        setRequireFetchingLoanProjection(false)
        setConceptsSchema(paymentConceptSchema)
        setConcepts(concepts)
        setPaymentConcepts([])
        setErrorsPaymentConcepts([])
        setPaymentSummary((prev) => ({
          ...prev,
          amount: currency(total),
        }))
        setCalculator((prev) => ({
          ...prev,
          concepts: 0,
          totalAmount: total,
          missingAmount,
        }))
      } catch (error) {
        if (error instanceof FormValidationError) {
          const errorLabels = {
            date: 'La fecha de pago es requerida',
          }
          setAlert({
            type: 'error',
            title: '¡Ups, algo salió mal!',
            label: errorLabels[error.fields] || error.message,
          })
        } else if (error instanceof ApiError) {
          setAlert({
            type: 'error',
            title: '¡Ups, algo salió mal!',
            label: error.message,
          })
        } else {
          setAlert({
            type: 'error',
            title: '¡Ups, algo salió mal!',
            label: `Ocurrió un error al obtener la información.
                  Por favor contacte con el soporte`,
          })
        }
      } finally {
        setIsFetchingLoanProjection(false)
      }
    },
    [loan?.id, paymentSummary.date]
  )

  useEffect(() => {
    const setForm = async () => {
      if (!loan) return
      setPaymentSummary((prev) => ({
        ...prev,
        date: MAX_DATE_PAY,
        reference: loan.paymentReference,
      }))

      try {
        const { concepts, repayments } =
          await LoanRepository.fetchLoanProjection({
            loanId: loan.id,
            date: getUTCDateTime(new Date(MAX_DATE_PAY)),
          })

        setLoanInformation({
          loading: false,
          concepts,
          repayments,
        })
      } catch (error) {
        if (error instanceof ApiError) {
          setAlert({
            type: 'error',
            title: error.title,
            label: error.message,
          })
        } else {
          setAlert({
            type: 'error',
            title: '¡Ups, algo salió mal!',
            label: `Ocurrió un error al obtener la información.
                  Por favor contacte con el soporte`,
          })
        }
      }
    }
    setForm()
  }, [loan])

  useEffect(() => {
    const totalAmount = FormatCurrency.extractNumber(paymentSummary.amount) || 0
    setCalculator((prev) => ({
      ...prev,
      totalAmount,
      missingAmount: gaussianRounding(totalAmount - prev.concepts),
    }))
  }, [paymentSummary.amount])

  useEffect(() => {
    if (paymentConcepts.length === 0) return
    const concepts = paymentConcepts.reduce(
      (prev, curr) => prev + FormatCurrency.extractNumber(curr.amount || 0),
      0
    )
    setCalculator((prev) => ({
      ...prev,
      concepts,
      missingAmount: gaussianRounding(prev.totalAmount - concepts),
    }))
  }, [paymentConcepts])

  return (
    <Styles>
      <Accordion>
        <AccordionSummary
          id='loan-details'
          expandIcon={<Icon name='keyboard_arrow_down' />}
          aria-controls='loan-details'
        >
          <h4>Id préstamo: {loan.id}</h4>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid size={6}>Id linea: {loan.lineId}</Grid>
            <Grid size={6}>Id relación: {loan.relationId}</Grid>
            <Grid size={6}>CRN: {loan.crn}</Grid>
            <Grid size={6}>
              Deuda total: {FormatCurrency.formatCurrency(loan.debt)}
            </Grid>
            <Grid size={6}>
              Capital adeudado: {FormatCurrency.formatCurrency(loan.amount)}
            </Grid>
            <Grid size={6}>Proveedor: {loan.providerName}</Grid>
            <Grid size={6}>
              Fecha de solicitud:{' '}
              {dateFormatFromIso(loan.createdAt, 'dd-MM-yyyy')}
            </Grid>
            <Grid size={6}>Divisa: {loan.currency}</Grid>
            <Grid size={6}>Producto: {loan.product}</Grid>
            <Grid size={6}>Fondeador: {loan.funder}</Grid>
            <Grid size={12}>
              <Accordion defaultExpanded>
                <AccordionSummary
                  id='debt-concepts'
                  aria-controls='debt-concepts'
                  expandIcon={<Icon name='keyboard_arrow_down' />}
                >
                  Conceptos de deuda
                </AccordionSummary>
                <AccordionDetails>
                  <List>
                    {loanInformation.loading && (
                      <Grid container spacing={1}>
                        <Grid size={6}>
                          <Skeleton
                            variant='rounded'
                            height={25}
                            width={'100%'}
                            animation='wave'
                          />
                        </Grid>
                        <Grid size={6}>
                          <Skeleton
                            variant='rounded'
                            height={25}
                            width={'100%'}
                            animation='wave'
                          />
                        </Grid>
                        <Grid size={6}>
                          <Skeleton
                            variant='rounded'
                            height={25}
                            width={'100%'}
                            animation='wave'
                          />
                        </Grid>
                        <Grid size={6}>
                          <Skeleton
                            variant='rounded'
                            height={25}
                            width={'100%'}
                            animation='wave'
                          />
                        </Grid>
                        <Grid size={6}>
                          <Skeleton
                            variant='rounded'
                            height={25}
                            width={'100%'}
                            animation='wave'
                          />
                        </Grid>
                        <Grid size={6}>
                          <Skeleton
                            variant='rounded'
                            height={25}
                            width={'100%'}
                            animation='wave'
                          />
                        </Grid>
                      </Grid>
                    )}
                    {!loanInformation.loading &&
                      loanInformation.concepts.map((concept) => (
                        <ListItem>
                          <Grid container spacing={1} width={'100%'}>
                            <Grid size={6}>{concept.name}</Grid>
                            <Grid size={6}>
                              {FormatCurrency.formatCurrency(concept.totalDebt)}
                            </Grid>
                          </Grid>
                        </ListItem>
                      ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>
      <Accordion>
        <AccordionSummary
          id='payments'
          aria-controls='payments'
          expandIcon={<Icon name='keyboard_arrow_down' />}
        >
          <h4>Pagos realizados</h4>
        </AccordionSummary>
        <AccordionDetails>
          {loanInformation.loading && (
            <Grid container spacing={1}>
              <Grid size={6}>
                <Skeleton
                  variant='rounded'
                  height={25}
                  width={'100%'}
                  animation='wave'
                />
              </Grid>
              <Grid size={6}>
                <Skeleton
                  variant='rounded'
                  height={25}
                  width={'100%'}
                  animation='wave'
                />
              </Grid>
              <Grid size={6}>
                <Skeleton
                  variant='rounded'
                  height={25}
                  width={'100%'}
                  animation='wave'
                />
              </Grid>
              <Grid size={6}>
                <Skeleton
                  variant='rounded'
                  height={25}
                  width={'100%'}
                  animation='wave'
                />
              </Grid>
              <Grid size={6}>
                <Skeleton
                  variant='rounded'
                  height={25}
                  width={'100%'}
                  animation='wave'
                />
              </Grid>
            </Grid>
          )}
          {!loanInformation.loading &&
            loanInformation.repayments.map((payment) => (
              <Accordion key={payment.id}>
                <AccordionSummary
                  id={payment.id}
                  aria-controls={payment.id}
                  expandIcon={<Icon name='keyboard_arrow_down' />}
                >
                  <Grid container width={'100%'}>
                    <Grid size={6}>
                      Fecha de pago:{' '}
                      {dateFormatFromIso(payment.appliedAt, 'dd-MM-yyyy')}
                    </Grid>
                    <Grid size={6}>
                      Total pagado:{' '}
                      {FormatCurrency.formatCurrency(payment.amount)}
                    </Grid>
                  </Grid>
                </AccordionSummary>
                <AccordionDetails>
                  <List subheader={<ListSubheader>Conceptos</ListSubheader>}>
                    {payment.charges.map((charge) => (
                      <ListItem>
                        <Grid container spacing={1} width={'100%'}>
                          <Grid size={6}>{charge.name}</Grid>
                          <Grid size={6}>
                            Monto:{' '}
                            {FormatCurrency.formatCurrency(charge.amount)}
                          </Grid>
                        </Grid>
                      </ListItem>
                    ))}
                  </List>
                </AccordionDetails>
              </Accordion>
            ))}
        </AccordionDetails>
      </Accordion>
      <Accordion defaultExpanded>
        <AccordionSummary
          id='payment-summary'
          aria-controls='payment-summary'
          expandIcon={<Icon name='keyboard_arrow_down' />}
          style={{ shadow: '1px 1px 1px 1px #000' }}
        >
          <h4>Resumen de pago</h4>
        </AccordionSummary>
        <AccordionDetails>
          <section className='payment-summary'>
            <form className='form-payment'>
              <div className='form-grid'>
                <Select
                  className='form-input'
                  label='Tipo de pago'
                  name='paymentType'
                  value={paymentSummary.paymentType}
                  onChange={(e) => onChangePaymentSummary(e)}
                  error={
                    validating &&
                    paymentSummary.paymentType === '' &&
                    'Campo Requerido'
                  }
                >
                  <option value='' disabled>
                    Seleccione un tipo de pago
                  </option>
                  {loan.status !== LOAN_STATUS.WRITE_OFF && (
                    <>
                      {/* TODO: Add this option when back fix errors related with overpayment */}
                      {/* <option value='Overpayment'>Sobre pago</option> */}
                      <option value='Voluntary'>Pago voluntario</option>
                      <option value='Condonation'>
                        Condonación de la deuda
                      </option>
                    </>
                  )}
                  {loan.status === LOAN_STATUS.WRITE_OFF && (
                    <option value='Recovery'>Recupero</option>
                  )}
                </Select>
                <Input
                  className='form-input'
                  label='Referencia'
                  name='reference'
                  value={paymentSummary.reference}
                  disabled
                />
                <Input
                  className='form-input'
                  label='Fecha de pago'
                  name='date'
                  type='datetime-local'
                  disabled={isFetchingLoanProjection}
                  value={paymentSummary.date}
                  onChange={(e) => {
                    onChangePaymentSummary(e)
                    setRequireFetchingLoanProjection(true)
                  }}
                  error={
                    validating &&
                    (paymentSummary.date < MIN_DATE_PAY ||
                      paymentSummary.date > MAX_DATE_PAY) &&
                    'Campo Requerido'
                  }
                  min={MIN_DATE_PAY}
                  max={MAX_DATE_PAY}
                />
                <LoadingButton
                  variant='outlined'
                  loading={isFetchingLoanProjection}
                  disabled={!requireFetchingLoanProjection}
                  onClick={fetchLoanProjection}
                  endIcon={<Icon name='calculate' />}
                  style={{ height: '50px' }}
                >
                  Calcular
                </LoadingButton>
              </div>
              {!requireFetchingLoanProjection && (
                <div className='form-grid'>
                  <Input
                    label='Monto total'
                    name='amount'
                    value={
                      paymentSummary.paymentType === 'Overpayment'
                        ? currency(loan.positiveBalance, {
                            style: undefined,
                            maximumFractionDigits: 2,
                          })
                        : paymentSummary.amount
                    }
                    disabled={
                      paymentSummary.paymentType === 'Overpayment' ||
                      isFetchingLoanProjection
                    }
                    onChange={(e) => onChangePaymentSummary(e)}
                    onFocus={(e) =>
                      onChangePaymentSummary(
                        FormatCurrency.handleFocusCurrency(e)
                      )
                    }
                    onBlur={(e) =>
                      onChangePaymentSummary(
                        FormatCurrency.handleBlurCurrency(e)
                      )
                    }
                    error={
                      validating &&
                      !validateValue(paymentSummary.amount, {
                        type: 'currency',
                        params: { min: 0.01 },
                      }) &&
                      'Campo Requerido'
                    }
                  />
                  <div />
                  <div />
                  <div />
                </div>
              )}
            </form>
            {!requireFetchingLoanProjection && (
              <section className='priority'>
                <label>¿Seguir la prelación de pago del sistema?</label>
                <Switch
                  firstOption='No'
                  secondOption='Si'
                  checked={paymentSummary.priority}
                  onChange={(e) =>
                    setPaymentSummary({
                      ...paymentSummary,
                      priority: e.target.checked,
                    })
                  }
                />
              </section>
            )}
          </section>
          {!paymentSummary.priority && isFetchingLoanProjection && (
            <section id='concepts-manager'>
              <Skeleton
                width={250}
                height={40}
                variant='rounded'
                animation='wave'
              />
            </section>
          )}
          {!paymentSummary.priority &&
            !isFetchingLoanProjection &&
            !requireFetchingLoanProjection && (
              <section id='concepts-manager'>
                <section className='payment-concepts'>
                  {paymentConcepts.length > 0 && (
                    <>
                      <h3 className='title'>Conceptos de pago</h3>
                      <div className='payment-concepts-container'>
                        <ul className='payment-concepts-list'>
                          {paymentConcepts.map((concept, index) => (
                            <li key={`concept-${index}`}>
                              <div className='form-concept'>
                                <Form
                                  schema={conceptsSchema}
                                  formValues={concept}
                                  setFormValues={(e) => {
                                    setPaymentConcepts((prev) => {
                                      prev[index] = e
                                      return [...prev]
                                    })
                                  }}
                                  formErrors={errorsPaymentConcepts[index]}
                                  setFormErrors={(errors) => {
                                    setErrorsPaymentConcepts((prev) => {
                                      prev[index] = errors
                                      return [...prev]
                                    })
                                  }}
                                  isValidating={validating}
                                />
                                <div className='remove-concept'>
                                  <Button
                                    onClick={() =>
                                      onRemovePaymentConcept(index)
                                    }
                                    color='error'
                                  >
                                    <Icon name='delete' type='outlined' />
                                  </Button>
                                </div>
                              </div>
                            </li>
                          ))}
                        </ul>
                        <div
                          className='calculator'
                          data-error={calculator.missingAmount !== 0}
                        >
                          <h4 className='bold'>Calculadora</h4>
                          <div className='calculator-values'>
                            <div className='calculator-value'>
                              <span className='bold'>Monto total:</span>{' '}
                              <span>{currency(calculator.totalAmount)}</span>
                            </div>
                            <div className='calculator-value'>
                              <span className='bold'>Suma de conceptos:</span>{' '}
                              <span>{currency(calculator.concepts)}</span>
                            </div>
                            <div className='calculator-value'>
                              <span className='bold'>
                                {calculator.missingAmount < 0
                                  ? 'Excedente'
                                  : 'Faltante'}
                                :
                              </span>{' '}
                              <span>
                                {currency(Math.abs(calculator.missingAmount))}
                              </span>
                            </div>
                          </div>
                        </div>
                      </div>
                    </>
                  )}
                </section>
                <Button
                  variant='text'
                  endIcon={<Icon name='add' />}
                  onClick={() => addPaymentConcept()}
                >
                  Agregar conceptos
                </Button>
              </section>
            )}
          <ButtonGroup className='actionGroup'>
            <Button
              variant='outlined'
              color='error'
              disabled={submitLoading}
              onClick={() => onHandleClose()}
            >
              Salir
            </Button>
            <LoadingButton
              variant='outlined'
              className='submit'
              disabled={isFetchingLoanProjection}
              onClick={() => onHandleSubmit()}
              loading={submitLoading}
            >
              Realizar Pago
            </LoadingButton>
          </ButtonGroup>
        </AccordionDetails>
      </Accordion>
    </Styles>
  )
}

const Styles = styled.div`
  position: relative;
  padding: 1rem 70px;
  min-height: calc(100vh - 260px);
  overflow-y: auto;

  .bold {
    font-weight: bold;
  }

  .title {
    margin-bottom: 1rem;
  }

  .payment-summary {
    .form-grid {
      display: grid;
      grid-template-columns: repeat(auto-fit, minmax(min(200px, 100%), 1fr));
      gap: 10px;
      margin-top: 1rem;
    }

    .priority {
      margin-block: 2rem;
    }
  }

  .add-concept {
    display: flex;
    align-items: center;
    gap: 1rem;

    .action {
      height: 30px;
      width: 30px;

      display: flex;
      align-items: center;
      justify-content: center;
      .icon {
        font-size: 1rem;
      }
    }
  }

  #concepts-manager {
    margin-block: 2rem;
    .payment-concepts-container {
      display: flex;
      justify-content: space-between;
      gap: 1rem;

      .payment-concepts-list {
        list-style: none;
        padding: 0;

        li + li {
          margin-top: 1.5rem;
        }

        .form-concept {
          display: flex;
          gap: 1rem;

          .form-input {
            width: max-content;
            flex: 1;
          }
        }
      }
    }

    .remove-concept {
      display: flex;
      align-items: center;
      justify-content: flex-start;
      margin-top: 15px;

      button.action {
        height: 40px;
        width: 40px;

        color: #b7b7b7;
        background-color: transparent;
        border: none;
      }
    }
  }

  .calculator {
    min-width: 380px;
    height: 180px;
    padding: 30px;

    background-color: white;
    border: 1px solid #00172d;
    border-radius: 9px;

    &[data-error='true'] {
      color: #ff0045;
      border-color: #ff0045;
    }

    .calculator-values {
      border-top: 1px solid #e2e2e2;
      padding-top: 10px;

      .calculator-value {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        column-gap: 1rem;
        margin-top: 5px;
      }
    }
  }

  .actionGroup {
    margin-top: 2rem;
    display: flex;
    justify-content: flex-end;

    .submit {
      background-color: #00172d;
      border-color: #00172d;
      color: white;

      &:disabled {
        opacity: 0.7;
        color: white;
      }
    }
  }
`

export default PaymentRegister
