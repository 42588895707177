import { Button, Icon, Textarea } from '@keoworld/gbl-ui-kit'
import { useState } from 'react'
import styled from 'styled-components'

const ConfirmationModal = (props) => {
  const {
    handleCloseModal,
    modalConfiguration,
    handleSubmit,
    isValidating = false,
  } = props
  const { title, message, requireReason, icon } = modalConfiguration

  const [isLoading, setIsLoading] = useState(false)
  const [reason, setReason] = useState('')

  const confirm = async () => {
    setIsLoading(true)
    await handleSubmit(reason)
    setIsLoading(false)
  }

  return (
    <Styles>
      <section className='modal'>
        <header>
          <h3>
            {icon && <Icon name={icon} type='outlined' />}
            <span>{title}</span>
          </h3>
          <p>{message}</p>
          {requireReason && (
            <>
              <Textarea
                label='Ingresa la razón por la que deseas realizar esta acción'
                name='reason'
                value={reason}
                onChange={(e) => {
                  setReason(e.target.value)
                }}
                error={isValidating && !reason}
              />
            </>
          )}
        </header>

        <footer className='actions'>
          <Button
            buttonType='outlineBlueDark'
            onClick={handleCloseModal}
            disabled={isLoading}
          >
            Cancelar
          </Button>
          <Button onClick={confirm} disabled={isLoading}>
            Aceptar
          </Button>
        </footer>
      </section>
    </Styles>
  )
}

const Styles = styled.div`
  position: absolute;
  z-index: 6;

  height: 100%;
  width: 100%;

  top: 0;
  left: 0;

  background-color: rgb(0, 0, 0, 0.4);

  .modal {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    background-color: white;

    padding-block: 2rem;
    padding-inline: 4rem;

    border-radius: 10px;

    max-width: 600px;

    header {
      margin-bottom: 2rem;
      text-align: center;

      h3 {
        display: flex;
        flex-direction: column;
        align-items: center;

        font-size: 16pt;
        text-wrap: balance;

        span.icon {
          font-size: 35pt;
        }
      }

      p {
        text-align: left;
      }

      textarea {
        background: transparent;
      }
    }

    footer {
      display: flex;
      justify-content: center;
      gap: 1rem;
    }
  }
`

export default ConfirmationModal
