import { Button, Select } from '@keoworld/gbl-ui-kit'
import { Skeleton } from '@mui/material'
import axios from 'axios'
import LateralBar from 'containers/lateral-bar'
import ModalUpload from 'containers/Modal/modal-documents-upload'
import { AlertContext } from 'providers/alert'
import { CustomerContext } from 'providers/customer'
import { useCallback, useContext, useEffect, useState } from 'react'
import {
  ApiDebtCapacityRepository,
  PROPOSAL_STATUS,
} from 'services/debt-capacity-suppcards/debtCapacity.repository'
import { ApiLineRepository } from 'services/line.repository'
import { getCustomerLines, getLineConditions, updateLine } from 'services/lines'
import styled from 'styled-components'
import { INTEREST_TYPES } from 'utils/constants'
import { calculateLineConditions } from 'utils/functions/calculate-line-conditions'
import { currency, percentage } from 'utils/functions/formatters'
import { ApiError } from 'utils/schemas/errors'
import { LINE_STATES, LINE_STATES_SPANISH } from 'utils/schemas/line'

const LINE_ACTIONS = {
  Cancel: 'cancelar',
  Freeze: 'congelar',
  Unfreeze: 'descongelar',
}

const initialModalConfig = {
  open: false,
  isLoading: false,
  title: '',
  actionLabel: '',
  action: '',
}

const LineManager = () => {
  const { customer } = useContext(CustomerContext)
  const { setAlert } = useContext(AlertContext)

  const [lateralBar, setLateralBar] = useState()
  const [currencySelected, setCurrencySelected] = useState('')
  const [customerCurrencies, setCustomerCurrencies] = useState([])
  const [lines, setLines] = useState([])
  const [lineSelected, setLineSelected] = useState()
  const [isFetching, setIsFetching] = useState(true)
  const [modalConfig, setModalConfig] = useState(initialModalConfig)

  const getLines = useCallback(async () => {
    try {
      if (currencySelected === '') return

      setIsFetching(true)
      setTimeout(async () => {
        const { data } = await getCustomerLines(
          customer.id,
          undefined,
          currencySelected
        )
        const linesNotCanceled = data.filter(
          (element) => element.status !== LINE_STATES.CANCELLED
        )
        linesNotCanceled.sort((a, b) => a.lineId - b.lineId)
        const lineConditions = await Promise.all(
          linesNotCanceled.map(async (line) => {
            const conditions = await getLineConditions(line.lineId)
            return {
              ...line,
              ...conditions,
            }
          })
        )
        setIsFetching(false)
        setLines(lineConditions)
      }, 5000)
    } catch (error) {
      console.log(error)
      setAlert({
        type: 'error',
        title: 'Error',
        label: 'Error al obtener las líneas',
      })
    }
  }, [customer?.id, currencySelected, setAlert])

  useEffect(() => {
    getLines()
  }, [getLines])

  useEffect(() => {
    const fetchApprovedProposals = async () => {
      const proposals = await ApiDebtCapacityRepository.getProposalByBuyerId(
        customer.id,
        PROPOSAL_STATUS.APPROVED
      )

      if (!proposals) return

      setCustomerCurrencies(
        proposals.debtCapacities.map((debtCapacity) => debtCapacity.currency)
      )

      if (proposals.debtCapacities.length > 0) {
        setCurrencySelected(proposals.debtCapacities[0]?.currency ?? '')
      }
    }

    fetchApprovedProposals()
  }, [customer?.id])

  const handleLineUpdate = async (action, lineId) => {
    const actionToState = {
      [LINE_ACTIONS.Cancel]: LINE_STATES.CANCELLED,
      [LINE_ACTIONS.Freeze]: LINE_STATES.FROZEN,
      [LINE_ACTIONS.Unfreeze]: LINE_STATES.ACTIVE,
    }

    try {
      const bodyRequest = {
        status: actionToState[action],
      }
      const data = await updateLine({ data: bodyRequest }, lineId)
      await getLines()
      if (data.failCallao && data.failCallao[0]?.error) {
        setAlert({
          type: 'error',
          title: 'Error',
          label: data.failCallao[0]?.error,
        })
      } else if (data.failItoc && data.failItoc[0]?.error) {
        setAlert({
          type: 'error',
          title: 'Error',
          label: 'Error en el servicio de I2C ',
        })
      }
    } catch (error) {
      const actionError = {
        [LINE_ACTIONS.Cancel]: 'cancelar',
        [LINE_ACTIONS.Freeze]: 'congelar',
        [LINE_ACTIONS.Unfreeze]: 'activar',
      }
      setAlert({
        type: 'error',
        title: `Error al ${actionError[action]} la línea`,
        label: error.response?.data?.detail,
      })
    } finally {
      setModalConfig(initialModalConfig)
    }
  }

  const handleSubmit = async () => {
    setModalConfig({ ...modalConfig, isLoading: true })

    const { lineId, externalId, hasPrimaryCard } = lineSelected
    const { action } = modalConfig

    if (!hasPrimaryCard) {
      return handleLineUpdate(action, lineId)
    }

    const data = {
      pathParams: {
        lineId: externalId,
      },
    }

    try {
      switch (action) {
        case LINE_ACTIONS.Freeze:
          await ApiLineRepository.freeze(externalId, data)
          break
        case LINE_ACTIONS.Unfreeze:
          await ApiLineRepository.unfreeze(externalId, data)
          break
        case LINE_ACTIONS.Cancel:
          await ApiLineRepository.cancel(externalId, data)
          break
        default:
          setAlert({
            type: 'error',
            title: '¡Ups! Ha ocurrido un error',
            label: `Error al ejecutar la acción.
                  Por favor recargue la página y vuelva a intentarlo`,
          })
          break
      }
    } catch (error) {
      console.error(error)
      if (error instanceof ApiError) {
        setAlert({
          title: error.title,
          label: error.message,
          type: error.statusCode === 500 ? 'error' : 'warning',
        })
      } else if (axios.isAxiosError(error)) {
        let message = `Error al ejecutar la acción.
          Por favor recargue la página y vuelva a intentarlo`

        if (data?.body?.message === 'Invalid event') {
          message = `Error al ejecutar la acción.
            Por favor comuníquese con soporte`
        }

        setAlert({
          type: 'error',
          title: '¡Ups! Ha ocurrido un error',
          label: message,
        })
      }
    } finally {
      await getLines()
      setModalConfig(initialModalConfig)
    }
  }

  const handleChangeCurrency = (event) => {
    setCurrencySelected(event.target.value)
  }

  return (
    <LineAdministrationStyled>
      <h2>Línea de crédito pre-aprobada</h2>
      {customerCurrencies.length > 0 && (
        <section>
          <Select
            className='currency-selector'
            label='Seleccione una moneda'
            name='currency'
            value={currencySelected}
            onChange={handleChangeCurrency}
          >
            <option value='' disabled>
              Seleccione una opción
            </option>
            {customerCurrencies.map((currency) => (
              <option key={currency} value={currency}>
                {currency}
              </option>
            ))}
          </Select>
          <Button
            className='grayButton'
            buttonType='grayButton'
            onClick={() => setLateralBar('CREATE_LINE')}
          >
            + Agregar nueva línea
          </Button>
        </section>
      )}

      <section>
        {isFetching && (
          <div className='skeleton-group'>
            <Skeleton variant='rounded' width={850} height={500} />
          </div>
        )}
        {!isFetching &&
          lines.map((line, index) => {
            const { annualReturn, termsConfig } = calculateLineConditions({
              score: line.score,
              maxTerm: line.maxTerm,
              terms: line.terms,
            })

            const buttonAction = (action) => () => {
              setLineSelected(line)
              setModalConfig({
                action,
                actionLabel: 'Confirmar',
                isLoading: false,
                open: true,
                title: `¿Estás seguro que deseas ${action} la línea de crédito?`,
              })
            }

            return (
              <div className='container' key={`line-${index}`}>
                <section className='mt-1'>
                  <p>
                    <strong>Línea {line.lineId}</strong>
                  </p>
                </section>
                <section className='header'>
                  <div>
                    <p>
                      <strong>Monto</strong>
                    </p>
                    <p className='amount'>
                      <span className='currency'>
                        {line.currency ?? 'MXN'}{' '}
                      </span>
                      <span>{currency(line.score)}</span>
                    </p>
                  </div>
                  <div className='header-texts'>
                    <p>{line.maxTerm} días de plazo máximo</p>
                    <p>
                      <span>{percentage(annualReturn)}</span>
                      <span> Rentabilidad Anual Estimada</span>
                    </p>
                    <p>
                      <span className='strong'>Fondeador: </span>
                      <span>{line.funderName}</span>
                    </p>
                    <p>
                      <span className='strong'>Producto: </span>
                      <span>{line.productName}</span>
                    </p>
                  </div>
                </section>
                <section className='container-cards'>
                  <div className='container-period'>
                    {/* Here .map of terms, if the position is greater than 1,
                  use the border-top class in addition to the period class */}
                    {line.terms.map((period, index) => {
                      const { annualRate = 0 } = termsConfig[index] ?? {}

                      return (
                        <div className='period' key={`period-${index}`}>
                          <div>
                            <p className='strong'>Periodo {index + 1}</p>
                            <p>{period.name}</p>
                          </div>
                          <div>
                            <p className='strong'>
                              Tasa anualizada: {percentage(annualRate)}
                            </p>
                            <p>{period.rate}%</p>
                          </div>
                          <div>
                            <p className='strong'>
                              {!period.delinquency && (
                                <span>
                                  Rango: Día {period.from} a {period.to}
                                </span>
                              )}
                              {period.delinquency && (
                                <span>
                                  Rango: Día {period.from} en adelante
                                </span>
                              )}
                            </p>
                            <p>{INTEREST_TYPES[period.typeId]}</p>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                  <div className='container-commission'>
                    {/* Here .map of commissions, if the position is greater than 1
                  use the border-top class in addition to the period class */}
                    {line.commissions.length === 0 &&
                      `La línea no cuenta con comisiones`}
                    {line.commissions.map((commission, index) => {
                      return (
                        <div className='commission'>
                          <p>
                            <strong>Comisión {index + 1}</strong>
                          </p>
                          <div className='display-flex'>
                            <p>{commission.name}</p>
                            <p>Días {commission.days.join(',')}</p>
                            <p>{currency(commission.amount)}</p>
                          </div>
                        </div>
                      )
                    })}
                  </div>
                </section>
                <div className='container-buttons'>
                  <div>
                    <p>
                      <strong>Estado: </strong>
                      <span>{LINE_STATES_SPANISH[line.status]}</span>
                    </p>
                  </div>
                  <div>
                    {line.status === LINE_STATES.ACTIVE && (
                      <Button
                        className='btn-cancel'
                        onClick={buttonAction(LINE_ACTIONS.Cancel)}
                      >
                        Cancelar línea
                      </Button>
                    )}
                    {line.status === LINE_STATES.FROZEN && (
                      <Button
                        className='btn-frozen'
                        onClick={buttonAction(LINE_ACTIONS.Unfreeze)}
                      >
                        Descongelar línea
                      </Button>
                    )}
                    {line.status === LINE_STATES.ACTIVE && (
                      <Button
                        className='btn-frozen'
                        onClick={buttonAction(LINE_ACTIONS.Freeze)}
                      >
                        Congelar línea
                      </Button>
                    )}
                    {line.status === LINE_STATES.ACTIVE && (
                      <Button
                        buttonType='green'
                        onClick={() => {
                          if (!line.hasPrimaryCard) {
                            return setAlert({
                              type: 'error',
                              title: '¡Atención!',
                              label: `
                                La línea que desea editar no cuenta con una tarjeta primaria.
                                Por favor, cree una nueva línea seleccionando el card program
                                para tarjetas primarias
                                `,
                            })
                          }

                          setLateralBar('EDIT')
                          setLineSelected(line)
                        }}
                      >
                        Editar línea
                      </Button>
                    )}
                  </div>
                </div>
              </div>
            )
          })}
      </section>
      {modalConfig.open && (
        <ModalUpload
          setOpenModalUpload={() => setModalConfig(initialModalConfig)}
          isLoadingSubmit={modalConfig.isLoading}
          handleSave={handleSubmit}
          messages={{
            msg: modalConfig.title,
            msgButton: modalConfig.actionLabel,
          }}
        />
      )}
      {lateralBar && (
        <LateralBar
          setLateralBar={setLateralBar}
          type={lateralBar}
          formValues={lateralBar !== 'CREATE_LINE' ? lineSelected : undefined}
          getLines={getLines}
        />
      )}
    </LineAdministrationStyled>
  )
}

const LineAdministrationStyled = styled.div`
  padding: 2rem;
  padding-bottom: 3rem;
  h2 {
    font-size: 18px;
  }
  .strong {
    font-weight: bold;
  }

  .display-flex {
    display: flex;
    flex-wrap: wrap;
  }

  .grayButton {
    margin-block: 1rem;
    width: 200px;
  }

  .currency-selector {
    margin-top: 1rem;
    max-width: 200px;
  }

  .container {
    width: 55rem;
    height: auto;
    margin-top: 2rem;
    padding: 1rem;
    background-color: #f6f8fc;
    border-radius: 8px;
    .mt-1 {
      margin-top: 1rem;
    }
    p {
      margin: 0 0.5rem;
    }
    .mr-1 {
      margin: 0rem 1rem 0 0;
    }
    .header {
      display: flex;
      margin: 1.2rem 0;
      .amount {
        font-size: 1.8rem;
        font-weight: 600;
        .currency {
          color: #00575b;
          text-transform: uppercase;
        }
      }
      .header-texts {
        display: flex;
        flex-direction: column;
        justify-content: end;
        padding-bottom: 0.4rem;
        margin-left: 3rem;
      }
    }
    .container-cards {
      display: flex;
      .container-period {
        width: 70%;
        background-color: white;
        padding: 1rem;
        border-radius: 8px;
        .period {
          padding: 0.5rem 0;
          display: grid;
          grid-template-columns: 1fr 1fr 1fr;
        }
        .period + .period {
          border-top: 1px solid #d5d2d2;
        }
      }
      .container-commission {
        background-color: white;
        padding: 1rem;
        border-radius: 8px;
        width: 33%;
        margin-left: 1%;
        .commission {
          padding: 0.5rem 0;
        }
        .commission + .commission {
          border-top: 1px solid #d5d2d2;
        }
      }
    }

    .container-buttons {
      display: flex;
      justify-content: space-between;
      border-top: 1px solid #d5d2d2;
      margin: 1.5rem 0rem 0 0;
      padding: 1.8rem 0rem 1rem;
      align-items: center;
      button {
        width: 131px;
        height: 34px;
        margin: auto 0.5rem;
      }
      .btn-cancel {
        background: #f5f5f5;
        color: rgb(47, 2, 26);
        border: 2px solid rgb(47, 2, 26);
      }
      .btn-frozen {
        background: #751644;
      }
    }
  }
`

export default LineManager
